import React from 'react';
import NotFound from '../../components/NotFound';

const Archivings = () => {
  return (
    <div>
      <NotFound />
    </div>
  );
};

export default Archivings;
