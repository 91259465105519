import React from 'react';
import '../../assets/css/App.css';
export default function EmployeesIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 512 512"
      >
        <g>
          <circle
            cx="158.82"
            cy="68.573"
            r="37.83"
            fill="#ffdfcf"
            data-original="#ffdfcf"
          ></circle>
          <path
            d="M122.122 203.99c19.684 0 35.698 16.015 35.698 35.698h2c0-19.684 16.014-35.698 35.698-35.698h38.962v-60.889c0-20.268-16.43-36.698-36.698-36.698h-77.923c-20.268 0-36.698 16.43-36.698 36.698v60.889h38.961z"
            fill="#8ac9fe"
            data-original="#8ac9fe"
          ></path>
          <path
            d="M234.48 203.99v-60.889c0-15.102-9.125-28.069-22.16-33.7v94.589h22.16z"
            fill="#60b7ff"
            data-original="#60b7ff"
          ></path>
          <circle
            cx="310.14"
            cy="68.573"
            r="37.83"
            fill="#c96e59"
            data-original="#c96e59"
          ></circle>
          <path
            d="M273.441 203.99c19.685 0 35.699 16.015 35.699 35.698h2c0-19.684 16.015-35.698 35.698-35.698H385.8v-60.889c0-20.268-16.431-36.698-36.698-36.698h-77.923c-20.268 0-36.698 16.43-36.698 36.698v60.889h38.96z"
            fill="#918291"
            data-original="#918291"
          ></path>
          <path
            d="M385.801 203.99v-60.889c0-15.102-9.125-28.069-22.16-33.7v94.589h22.16z"
            fill="#7a6d79"
            data-original="#7a6d79"
          ></path>
          <circle
            cx="83.16"
            cy="165.16"
            r="37.83"
            fill="#c96e59"
            data-original="#c96e59"
          ></circle>
          <path
            d="M158.82 307.789H17.5c-5.523 0-10-4.477-10-10v-58.1c0-20.268 16.43-36.698 36.698-36.698h77.923c20.268 0 36.698 16.43 36.698 36.698v68.1z"
            fill="#ffd15b"
            data-original="#ffd15b"
          ></path>
          <path
            d="M158.82 307.789H17.5c-5.523 0-10-4.477-10-10v-58.1c0-20.268 16.43-36.698 36.698-36.698h77.923c20.268 0 36.698 16.43 36.698 36.698v68.1z"
            fill="#ffe177"
            data-original="#ffe177"
          ></path>
          <circle
            cx="234.48"
            cy="165.16"
            r="37.83"
            fill="#ffdfcf"
            data-original="#ffdfcf"
          ></circle>
          <path
            d="M310.14 297.861v-58.172c0-20.268-16.43-36.698-36.698-36.698h-77.923c-20.268 0-36.698 16.431-36.698 36.698v68.1h143.636a110.909 110.909 0 0 1 7.683-9.928z"
            fill="#46cc8d"
            data-original="#46cc8d"
          ></path>
          <circle
            cx="385.8"
            cy="165.16"
            r="37.83"
            fill="#ffdfcf"
            data-original="#ffdfcf"
          ></circle>
          <path
            d="M393.975 128.226A37.647 37.647 0 0 1 401 150.17c0 20.893-16.937 37.83-37.83 37.83-2.808 0-5.541-.315-8.175-.896 6.86 9.612 18.096 15.886 30.805 15.886 20.893 0 37.83-16.937 37.83-37.83 0-18.085-12.693-33.196-29.655-36.934zM166.995 31.639a37.647 37.647 0 0 1 7.025 21.944c0 20.893-16.937 37.83-37.83 37.83-2.808 0-5.541-.315-8.175-.896 6.86 9.612 18.096 15.886 30.805 15.886 20.893 0 37.83-16.937 37.83-37.83 0-18.085-12.694-33.197-29.655-36.934zM242.655 128.226a37.647 37.647 0 0 1 7.025 21.944c0 20.893-16.937 37.83-37.83 37.83-2.808 0-5.541-.315-8.175-.896 6.86 9.612 18.096 15.886 30.805 15.886 20.893 0 37.83-16.937 37.83-37.83 0-18.085-12.693-33.196-29.655-36.934z"
            fill="#ffcebf"
            data-original="#ffcebf"
          ></path>
          <path
            d="M91.335 128.226a37.647 37.647 0 0 1 7.025 21.944c0 20.893-16.937 37.83-37.83 37.83-2.808 0-5.541-.315-8.175-.896 6.86 9.612 18.096 15.886 30.805 15.886 20.893 0 37.83-16.937 37.83-37.83 0-18.085-12.694-33.196-29.655-36.934zM318.315 31.639a37.647 37.647 0 0 1 7.025 21.944c0 20.893-16.937 37.83-37.83 37.83-2.808 0-5.541-.315-8.175-.896 6.86 9.612 18.096 15.886 30.805 15.886 20.893 0 37.83-16.937 37.83-37.83 0-18.085-12.693-33.197-29.655-36.934z"
            fill="#bb5d4c"
            data-original="#bb5d4c"
          ></path>
          <path
            d="M393.31 259.876c25.707 0 49.385 8.851 68.151 23.663v-43.85c0-20.268-16.43-36.698-36.698-36.698H346.84c-20.268 0-36.698 16.431-36.698 36.698v58.172c20.217-23.257 50.004-37.985 83.168-37.985z"
            fill="#fe5ca7"
            data-original="#fe5ca7"
          ></path>
          <path
            d="M461.46 283.539v-43.85c0-15.094-9.116-28.055-22.14-33.691v63.957a110.353 110.353 0 0 1 22.14 13.584z"
            fill="#fe3d97"
            data-original="#fe3d97"
          ></path>
          <path
            d="M310.14 297.861v-58.172c0-15.094-9.116-28.055-22.14-33.691V276c0 5.523-4.477 10-10 10H158.82v21.789h143.636a111.104 111.104 0 0 1 7.684-9.928z"
            fill="#00c27a"
            data-original="#00c27a"
          ></path>
          <path
            d="M136.68 205.998V276c0 5.523-4.477 10-10 10H7.5v11.789c0 5.523 4.477 10 10 10h141.32v-68.1c0-15.095-9.116-28.055-22.14-33.691z"
            fill="#ffd15b"
            data-original="#ffd15b"
          ></path>
          <circle
            cx="393.309"
            cy="370.067"
            r="111.191"
            fill="#8ac9fe"
            data-original="#8ac9fe"
          ></circle>
          <path
            d="M428.029 264.412C456.449 284.554 475 317.705 475 355.191c0 61.409-49.782 111.191-111.191 111.191a111.078 111.078 0 0 1-34.72-5.536c18.138 12.855 40.296 20.412 64.22 20.412 61.409 0 111.191-49.782 111.191-111.191 0-49.284-32.067-91.071-76.471-105.655z"
            fill="#60b7ff"
            data-original="#60b7ff"
          ></path>
          <path
            d="M333.793 354.258h38.405a5.302 5.302 0 0 0 5.302-5.302v-38.405a5.302 5.302 0 0 1 5.302-5.302h21.015a5.302 5.302 0 0 1 5.302 5.302v38.405a5.302 5.302 0 0 0 5.302 5.302h38.405a5.302 5.302 0 0 1 5.302 5.302v21.015a5.302 5.302 0 0 1-5.302 5.302H414.42a5.302 5.302 0 0 0-5.302 5.302v38.405a5.302 5.302 0 0 1-5.302 5.302h-21.015a5.302 5.302 0 0 1-5.302-5.302v-38.405a5.302 5.302 0 0 0-5.302-5.302h-38.405a5.302 5.302 0 0 1-5.302-5.302V359.56a5.304 5.304 0 0 1 5.303-5.302z"
            fill="#ffffff"
            data-original="#ffffff"
          ></path>
          <path
            d="M452.825 354.257h-9.19v11.433a5.302 5.302 0 0 1-5.302 5.302h-33.707c-5.523 0-10 4.477-10 10v33.707a5.302 5.302 0 0 1-5.302 5.302H377.5v9.583a5.302 5.302 0 0 0 5.302 5.302h21.015a5.302 5.302 0 0 0 5.302-5.302v-38.405a5.302 5.302 0 0 1 5.302-5.302h38.405a5.302 5.302 0 0 0 5.302-5.302V359.56a5.304 5.304 0 0 0-5.303-5.303z"
            fill="#f3f0f3"
            data-original="#f3f0f3"
          ></path>
          <path
            d="M382.32 127.496c-5.865-12.463-18.531-21.093-33.218-21.093h-77.923c-14.687 0-27.352 8.63-33.218 21.093M7.5 279.041v18.748c0 5.523 4.477 10 10 10h141.32v-68.1c0-20.268-16.431-36.698-36.698-36.698H44.198C23.93 202.99 7.5 219.421 7.5 239.689v4.352M7.5 365.103l17.103 16.768 23.794-28.628M80.605 367.557h167.584M7.5 418.118l17.103 16.768 23.794-28.628M80.605 420.572h167.584"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></path>
          <circle
            cx="158.82"
            cy="68.573"
            r="37.83"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></circle>
          <circle
            cx="310.14"
            cy="68.573"
            r="37.83"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></circle>
          <circle
            cx="83.16"
            cy="165.16"
            r="37.83"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></circle>
          <circle
            cx="234.48"
            cy="165.16"
            r="37.83"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></circle>
          <circle
            cx="385.8"
            cy="165.16"
            r="37.83"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></circle>
          <path
            d="M230.999 127.496c-5.865-12.463-18.531-21.093-33.218-21.093h-77.923c-14.687 0-27.352 8.63-33.218 21.093"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></path>
          <path
            d="M321.661 455.088c19.352 16.325 44.349 26.169 71.649 26.169 61.409 0 111.191-49.782 111.191-111.191S454.719 258.875 393.31 258.875c-61.409 0-111.191 49.782-111.191 111.191 0 21.45 6.08 41.478 16.602 58.464"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></path>
          <path
            d="M333.793 354.258h38.405a5.302 5.302 0 0 0 5.302-5.302v-38.405a5.302 5.302 0 0 1 5.302-5.302h21.015a5.302 5.302 0 0 1 5.302 5.302v38.405a5.302 5.302 0 0 0 5.302 5.302h38.405a5.302 5.302 0 0 1 5.302 5.302v21.015a5.302 5.302 0 0 1-5.302 5.302H414.42a5.302 5.302 0 0 0-5.302 5.302v38.405a5.302 5.302 0 0 1-5.302 5.302h-21.015a5.302 5.302 0 0 1-5.302-5.302v-38.405a5.302 5.302 0 0 0-5.302-5.302h-38.405a5.302 5.302 0 0 1-5.302-5.302V359.56a5.304 5.304 0 0 1 5.303-5.302z"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></path>
          <path
            d="M461.46 282.21v-42.522c0-20.268-16.43-36.698-36.698-36.698h-77.923c-20.268 0-36.698 16.431-36.698 36.698v56.589"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></path>
          <path
            d="M310.14 296.277v-56.589c0-20.268-16.43-36.698-36.698-36.698h-77.923c-20.268 0-36.698 16.431-36.698 36.698v68.1H195M230 307.789h71.184"
            fill="none"
            stroke="#000000"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            data-original="#000000"
          ></path>
        </g>
      </svg>
    </div>
  );
}
