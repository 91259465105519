import React from 'react';
import '../../assets/css/App.css';
export default function DocsIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 512 512"
        className="hovered-paths"
      >
        <g>
          <g fillRule="evenodd">
            <path
              fill="#006694"
              d="M.516 189.341 142.559 25.084a2.066 2.066 0 0 1 2.89-.2l210.409 181.942a2.105 2.105 0 0 1 .2 2.92L214.019 374a2.1 2.1 0 0 1-2.92.205L.72 192.26a2.048 2.048 0 0 1-.2-2.919z"
              opacity="1"
              data-original="#006694"
            ></path>
            <path
              fill="#005c85"
              d="M94.773 112.014 193.935 66.8l161.923 140.026a2.105 2.105 0 0 1 .2 2.92L214.019 374a2.1 2.1 0 0 1-2.92.205l-10.07-8.728L90.57 123.253a8.5 8.5 0 0 1 4.2-11.239z"
              opacity="1"
              data-original="#005c85"
            ></path>
            <path
              fill="#60b7ff"
              d="m97.43 117.852 197.593-90.083a2.063 2.063 0 0 1 2.715 1.022L413.13 281.877a2.05 2.05 0 0 1-.992 2.744L214.544 374.7a2.106 2.106 0 0 1-2.744-1.022L96.408 120.6a2.087 2.087 0 0 1 1.022-2.744z"
              opacity="1"
              data-original="#60b7ff"
            ></path>
            <path
              fill="#56a5e6"
              d="M214.719 86.852h109.5l88.911 195.025a2.05 2.05 0 0 1-.992 2.744L214.544 374.7a2.106 2.106 0 0 1-2.744-1.022l-5.546-12.2V95.346a8.476 8.476 0 0 1 8.465-8.494z"
              opacity="1"
              data-original="#56a5e6"
            ></path>
            <path
              fill="#eaf6ff"
              d="M214.719 93.274h161.718l57.506 57.506v222.7a2.081 2.081 0 0 1-2.072 2.073H214.719a2.056 2.056 0 0 1-2.043-2.073V95.346a2.055 2.055 0 0 1 2.043-2.072z"
              opacity="1"
              data-original="#eaf6ff"
              className="hovered-path"
            ></path>
            <path
              fill="#bec7cf"
              d="M376.437 93.274v44.6a12.925 12.925 0 0 0 12.9 12.9h44.6z"
              opacity="1"
              data-original="#bec7cf"
            ></path>
            <path
              fill="#ffba57"
              d="M264.46 289.379h169.688a32.559 32.559 0 0 1 32.46 32.46v133.315a32.541 32.541 0 0 1-32.46 32.461H133.306a32.56 32.56 0 0 1-32.461-32.461V256.918a26.723 26.723 0 0 1 26.681-26.651h56.659c9.4 0 15.793 5.78 22.506 12.319l35.263 34.474c6.714 6.568 13.107 12.319 22.506 12.319z"
              opacity="1"
              data-original="#ffba57"
            ></path>
          </g>
          <path
            fill="#bec7cf"
            d="M248.026 149.992a7.211 7.211 0 0 1 0-14.421h95.338a7.211 7.211 0 0 1 0 14.421zm0 105.2a7.21 7.21 0 1 1 0-14.42h150.567a7.21 7.21 0 1 1 0 14.42zm0-35.058a7.211 7.211 0 0 1 0-14.421h150.567a7.211 7.211 0 1 1 0 14.421zm0-35.059a7.21 7.21 0 1 1 0-14.42h150.567a7.21 7.21 0 1 1 0 14.42z"
            opacity="1"
            data-original="#bec7cf"
          ></path>
          <path
            fill="#cf9646"
            d="M146.646 400.567a7.21 7.21 0 1 1 0-14.42h70.321a7.21 7.21 0 0 1 0 14.42zm0 49.245a7.21 7.21 0 1 1 0-14.42h274.161a7.21 7.21 0 1 1 0 14.42z"
            opacity="1"
            data-original="#cf9646"
          ></path>
          <path
            fill="#e6a74e"
            fillRule="evenodd"
            d="M433.943 294.837a32.489 32.489 0 0 1 14.509 27v133.317a32.542 32.542 0 0 1-32.461 32.461h18.157a32.541 32.541 0 0 0 32.46-32.461V321.839a32.018 32.018 0 0 0-2.481-12.406 32.6 32.6 0 0 0-29.979-20.054h-18.157a32.239 32.239 0 0 1 17.952 5.458zM241.954 277.06l-29.278-28.607-5.985-5.867c-6.713-6.539-13.106-12.319-22.506-12.319h-18.127c9.37 0 15.763 5.78 22.477 12.319l24.141 23.586 11.151 10.888c6.684 6.568 13.077 12.319 22.477 12.319h18.156c-9.4 0-15.792-5.751-22.506-12.319z"
            opacity="1"
            data-original="#e6a74e"
          ></path>
          <path
            fill="#25c73b"
            fillRule="evenodd"
            d="M450.553 385.213a61.447 61.447 0 1 0-61.447-61.447 61.562 61.562 0 0 0 61.447 61.447z"
            opacity="1"
            data-original="#25c73b"
          ></path>
          <path
            fill="#eaf6ff"
            d="M413.831 332.114a7.214 7.214 0 1 1 10.188-10.214l13.836 13.836 39.2-39.2a7.2 7.2 0 0 1 10.188 10.187l-44.309 44.307a7.223 7.223 0 0 1-10.187 0z"
            opacity="1"
            data-original="#eaf6ff"
            className="hovered-path"
          ></path>
          <path
            fill="#21b335"
            fillRule="evenodd"
            d="M445.328 262.523a61.46 61.46 0 0 1 3.124 122.135 53.257 53.257 0 0 1-3.124.35c1.051.088 2.073.146 3.124.176.7.029 1.4.029 2.1.029a60.705 60.705 0 0 0 16.055-2.131 61.449 61.449 0 0 0-16.055-120.763c-1.752 0-3.5.058-5.225.2z"
            opacity="1"
            data-original="#21b335"
          ></path>
          <path
            fill="#d3dde6"
            fillRule="evenodd"
            d="M420.807 150.78v119.187a60.88 60.88 0 0 1 13.136-5.371V150.78z"
            opacity="1"
            data-original="#d3dde6"
          ></path>
        </g>
      </svg>
    </div>
  );
}
