import React from 'react';
import '../../assets/css/App.css';
export default function ToDoIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 100 100"
      >
        <g>
          <path
            fill="#71a58a"
            d="M64.714 100H14.398c-4.565 0-8.3-3.735-8.3-8.3V14.741c0-4.565 3.735-8.3 8.3-8.3h50.317c4.565 0 8.3 3.735 8.3 8.3V91.7c-.001 4.565-3.736 8.3-8.301 8.3z"
            opacity="1"
            data-original="#71a58a"
          ></path>
          <path
            fill="#ffeead"
            d="M12.292 13.224H66.82v79.992H12.292z"
            opacity="1"
            data-original="#ffeead"
          ></path>
          <path
            fill="#ffffff"
            d="M23.952 31.022h-2.691c-.631 0-1.143.512-1.143 1.143v2.691c0 .631.512 1.143 1.143 1.143h2.691c.631 0 1.143-.512 1.143-1.143v-2.691c0-.632-.512-1.143-1.143-1.143z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#ff6f69"
            d="M23.952 30.384h-2.691c-.982 0-1.781.799-1.781 1.781v2.691c0 .982.799 1.781 1.781 1.781h2.691c.982 0 1.781-.799 1.781-1.781v-2.691a1.782 1.782 0 0 0-1.781-1.781zm.505 4.472a.506.506 0 0 1-.505.505h-2.691a.506.506 0 0 1-.505-.505v-2.691c0-.278.227-.505.505-.505h2.691c.278 0 .505.227.505.505z"
            opacity="1"
            data-original="#ff6f69"
          ></path>
          <path
            fill="#99734a"
            d="M31.557 29.428a1.275 1.275 0 0 0-1.746-.457 14.679 14.679 0 0 0-4.961 4.794 10.355 10.355 0 0 0-2.371-2.149 1.276 1.276 0 1 0-1.401 2.134 7.767 7.767 0 0 1 2.726 3.1c.214.44.661.718 1.148.718h.029c.5-.011.946-.313 1.143-.771a12.113 12.113 0 0 1 4.976-5.622 1.277 1.277 0 0 0 .457-1.747z"
            opacity="1"
            data-original="#99734a"
          ></path>
          <path
            fill="#96ceb4"
            d="M58.432 32.547H36.309c-.66 0-1.199.54-1.199 1.199v.183c0 .66.54 1.2 1.199 1.2h22.124c.66 0 1.199-.54 1.199-1.2v-.183c0-.659-.54-1.199-1.2-1.199z"
            opacity="1"
            data-original="#96ceb4"
          ></path>
          <path
            fill="#ffffff"
            d="M23.952 44.381h-2.691c-.631 0-1.143.512-1.143 1.143v2.691c0 .631.512 1.143 1.143 1.143h2.691c.631 0 1.143-.512 1.143-1.143v-2.691c0-.632-.512-1.143-1.143-1.143z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#ff6f69"
            d="M23.952 43.743h-2.691c-.982 0-1.781.799-1.781 1.781v2.691c0 .982.799 1.781 1.781 1.781h2.691c.982 0 1.781-.799 1.781-1.781v-2.691a1.782 1.782 0 0 0-1.781-1.781zm.505 4.472a.506.506 0 0 1-.505.505h-2.691a.506.506 0 0 1-.505-.505v-2.691c0-.278.227-.505.505-.505h2.691c.278 0 .505.227.505.505z"
            opacity="1"
            data-original="#ff6f69"
          ></path>
          <path
            fill="#99734a"
            d="M31.557 42.787a1.275 1.275 0 0 0-1.746-.457 14.679 14.679 0 0 0-4.961 4.794 10.355 10.355 0 0 0-2.371-2.149 1.276 1.276 0 1 0-1.401 2.134 7.767 7.767 0 0 1 2.726 3.1c.214.44.661.718 1.148.718h.029c.5-.011.946-.313 1.143-.771a12.113 12.113 0 0 1 4.976-5.622 1.277 1.277 0 0 0 .457-1.747z"
            opacity="1"
            data-original="#99734a"
          ></path>
          <path
            fill="#96ceb4"
            d="M58.432 45.906H36.309c-.66 0-1.199.54-1.199 1.199v.183c0 .66.54 1.2 1.199 1.2h22.124c.66 0 1.199-.54 1.199-1.2v-.183c0-.659-.54-1.199-1.2-1.199z"
            opacity="1"
            data-original="#96ceb4"
          ></path>
          <path
            fill="#ffffff"
            d="M23.952 57.739h-2.691c-.631 0-1.143.512-1.143 1.143v2.691c0 .631.512 1.143 1.143 1.143h2.691c.631 0 1.143-.512 1.143-1.143v-2.691c0-.631-.512-1.143-1.143-1.143z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#ff6f69"
            d="M23.952 57.102h-2.691c-.982 0-1.781.799-1.781 1.781v2.691c0 .982.799 1.781 1.781 1.781h2.691c.982 0 1.781-.799 1.781-1.781v-2.691a1.782 1.782 0 0 0-1.781-1.781zm.505 4.472a.506.506 0 0 1-.505.505h-2.691a.506.506 0 0 1-.505-.505v-2.691c0-.278.227-.505.505-.505h2.691c.278 0 .505.227.505.505z"
            opacity="1"
            data-original="#ff6f69"
          ></path>
          <path
            fill="#99734a"
            d="M31.557 56.146a1.275 1.275 0 0 0-1.746-.457 14.679 14.679 0 0 0-4.961 4.794 10.355 10.355 0 0 0-2.371-2.149 1.276 1.276 0 1 0-1.401 2.134 7.767 7.767 0 0 1 2.726 3.1c.214.44.661.718 1.148.718h.029c.5-.011.946-.313 1.143-.771a12.113 12.113 0 0 1 4.976-5.622 1.277 1.277 0 0 0 .457-1.747z"
            opacity="1"
            data-original="#99734a"
          ></path>
          <path
            fill="#96ceb4"
            d="M58.432 59.265H36.309c-.66 0-1.199.54-1.199 1.199v.183c0 .66.54 1.2 1.199 1.2h22.124c.66 0 1.199-.54 1.199-1.2v-.183c0-.659-.54-1.199-1.2-1.199z"
            opacity="1"
            data-original="#96ceb4"
          ></path>
          <path
            fill="#ffffff"
            d="M23.952 71.098h-2.691c-.631 0-1.143.512-1.143 1.143v2.691c0 .631.512 1.143 1.143 1.143h2.691c.631 0 1.143-.512 1.143-1.143v-2.691c0-.631-.512-1.143-1.143-1.143z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#ff6f69"
            d="M23.952 70.461h-2.691c-.982 0-1.781.799-1.781 1.781v2.691c0 .982.799 1.781 1.781 1.781h2.691c.982 0 1.781-.799 1.781-1.781v-2.691a1.782 1.782 0 0 0-1.781-1.781zm.505 4.472a.506.506 0 0 1-.505.505h-2.691a.506.506 0 0 1-.505-.505v-2.691c0-.278.227-.505.505-.505h2.691c.278 0 .505.227.505.505z"
            opacity="1"
            data-original="#ff6f69"
          ></path>
          <path
            fill="#99734a"
            d="M31.557 69.505a1.275 1.275 0 0 0-1.746-.457 14.679 14.679 0 0 0-4.961 4.794 10.355 10.355 0 0 0-2.371-2.149 1.276 1.276 0 1 0-1.401 2.134 7.767 7.767 0 0 1 2.726 3.1c.214.44.661.718 1.148.718h.029c.5-.011.946-.313 1.143-.771a12.113 12.113 0 0 1 4.976-5.622 1.277 1.277 0 0 0 .457-1.747z"
            opacity="1"
            data-original="#99734a"
          ></path>
          <path
            fill="#96ceb4"
            d="M58.432 72.624H36.309c-.66 0-1.199.54-1.199 1.199v.183c0 .66.54 1.2 1.199 1.2h22.124c.66 0 1.199-.54 1.199-1.2v-.183c0-.659-.54-1.199-1.2-1.199z"
            opacity="1"
            data-original="#96ceb4"
          ></path>
          <path
            fill="#e05858"
            d="M47.397 4.979h-3.479c.024-.185.056-.368.056-.56a4.42 4.42 0 0 0-8.838 0c0 .192.033.375.056.56h-3.479c-.867 0-1.57.638-1.57 1.425v7.164c0 .787.703 1.425 1.57 1.425h15.683c.867 0 1.57-.638 1.57-1.425V6.404c.001-.787-.702-1.425-1.569-1.425zm-9.769-.56a1.928 1.928 0 1 1 3.856 0 1.928 1.928 0 0 1-3.856 0z"
            opacity="1"
            data-original="#e05858"
          ></path>
          <path
            fill="#ff6f69"
            d="M47.397 4.979h-3.479l.002-.018h-2.531c-.236.797-.959 1.386-1.833 1.386s-1.597-.588-1.833-1.386h-2.531l.002.018h-3.479c-.867 0-1.57.638-1.57 1.425v1.471h18.823V6.404c-.001-.787-.704-1.425-1.571-1.425z"
            opacity="1"
            data-original="#ff6f69"
          ></path>
          <path
            fill="#639376"
            d="M66.82 44.192V58.01l6.194-4.863V39.329z"
            opacity="1"
            data-original="#639376"
          ></path>
          <path
            fill="#c9b77d"
            d="M58.432 59.265c.66 0 1.199.54 1.199 1.199v.183c0 .66-.54 1.2-1.199 1.2H44.331l-.262.206c-1.562 1.227-4.013 6.26-1.659 8.212s8.657.113 10.22-1.114l14.19-11.14V44.192l-19.2 15.073z"
            opacity="1"
            data-original="#c9b77d"
          ></path>
          <path
            fill="#71a58a"
            d="M59.632 60.647v-.183c0-.66-.54-1.199-1.199-1.199H47.619l-3.288 2.581h14.101c.66.001 1.2-.539 1.2-1.199z"
            opacity="1"
            data-original="#71a58a"
          ></path>
          <path
            fill="#ff6f69"
            d="M91.705 5.132c-2.325-1.746-5.557-1.403-7.182.761L44.4 59.331c-1.625 2.164-4.275 9.656-1.95 11.402s8.78-2.889 10.405-5.054l40.123-53.438c1.625-2.164 1.052-5.363-1.273-7.109z"
            opacity="1"
            data-original="#ff6f69"
          ></path>
          <path
            fill="#ffeead"
            d="m44.943 58.608-.543.723c-1.625 2.164-4.275 9.656-1.95 11.402s8.78-2.889 10.405-5.054l.543-.723z"
            opacity="1"
            data-original="#ffeead"
          ></path>
          <path
            fill="#99734a"
            d="M41.723 66.26c-.345 1.96-.258 3.733.727 4.473s2.712.328 4.498-.549z"
            opacity="1"
            data-original="#99734a"
          ></path>
          <path
            fill="#96ceb4"
            d="M91.705 5.132c-2.325-1.746-5.557-1.403-7.182.761l-4.306 5.735 8.455 6.348 4.306-5.735c1.625-2.164 1.052-5.363-1.273-7.109z"
            opacity="1"
            data-original="#96ceb4"
          ></path>
          <path
            fill="#ffeead"
            d="m80.184 11.597 1.432-1.909 8.458 6.344-1.431 1.909z"
            opacity="1"
            data-original="#ffeead"
          ></path>
        </g>
      </svg>
    </div>
  );
}
