import React from 'react';

const ForgetPassword = () => {
  return (
    <div>
      <h1>Forget Password</h1>
    </div>
  );
};

export default ForgetPassword;
