import React from 'react';
import '../../assets/css/App.css';
export default function DashboardIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 512.001 512"
      >
        <g>
          <path
            fill="#f4f4f4"
            d="M488.184 478.914H23.816C10.664 478.914 0 468.25 0 455.098V27.168C0 12.164 12.164 0 27.164 0H486.27C500.48 0 512 11.52 512 25.73v429.368c0 13.152-10.664 23.816-23.816 23.816zm0 0"
            opacity="1"
            data-original="#f4f4f4"
          ></path>
          <path
            fill="#e8e8e8"
            d="M486.273 0h-11.949a26.36 26.36 0 0 1 1.172 7.797v463.32c0 2.715-.41 5.332-1.172 7.797h13.86c13.156 0 23.816-10.664 23.816-23.816V25.727C512 11.52 500.48 0 486.273 0zm0 0"
            opacity="1"
            data-original="#e8e8e8"
          ></path>
          <path
            fill="#5994ce"
            d="M0 92.164h512V24.535C512 10.985 501.016 0 487.465 0H24.535C10.985 0 0 10.984 0 24.535zm0 0"
            opacity="1"
            data-original="#5994ce"
          ></path>
          <path
            fill="#4c7eb0"
            d="M487.465 0h-13.14a26.36 26.36 0 0 1 1.171 7.797v84.367H512V24.535C512 10.985 501.016 0 487.465 0zm0 0"
            opacity="1"
            data-original="#4c7eb0"
          ></path>
          <path
            fill="#fa5d72"
            d="M79.64 46.082c0 10.043-8.14 18.188-18.187 18.188-10.043 0-18.183-8.145-18.183-18.188s8.14-18.187 18.183-18.187c10.047 0 18.188 8.144 18.188 18.187zm0 0"
            opacity="1"
            data-original="#fa5d72"
          ></path>
          <path
            fill="#f6d867"
            d="M141.207 46.082c0 10.043-8.144 18.188-18.187 18.188-10.047 0-18.188-8.145-18.188-18.188s8.14-18.187 18.188-18.187c10.043 0 18.187 8.144 18.187 18.187zm0 0"
            opacity="1"
            data-original="#f6d867"
          ></path>
          <path
            fill="#279463"
            d="M202.77 46.082c0 10.043-8.145 18.188-18.188 18.188s-18.187-8.145-18.187-18.188 8.144-18.187 18.187-18.187 18.188 8.144 18.188 18.187zm0 0"
            opacity="1"
            data-original="#279463"
          ></path>
          <path
            fill="#a7c3de"
            d="M0 92.164V454.38c0 13.55 10.984 24.535 24.535 24.535h141.86V92.164zm0 0"
            opacity="1"
            data-original="#a7c3de"
          ></path>
          <path
            fill="#93acc4"
            d="M151.5 92.164h14.895v386.75H151.5zm0 0"
            opacity="1"
            data-original="#93acc4"
          ></path>
          <path
            fill="#fa5d72"
            d="M314.012 226.582H206.23a7.922 7.922 0 0 1-7.921-7.922v-80.789a7.922 7.922 0 0 1 7.921-7.922h107.782a7.922 7.922 0 0 1 7.922 7.922v80.79a7.922 7.922 0 0 1-7.922 7.921zm0 0"
            opacity="1"
            data-original="#fa5d72"
          ></path>
          <path
            fill="#e35567"
            d="M314.012 129.95h-18.715a8.91 8.91 0 0 1 1.121 4.327v87.977a8.9 8.9 0 0 1-1.121 4.328h18.715a7.922 7.922 0 0 0 7.922-7.922v-80.789a7.922 7.922 0 0 0-7.922-7.922zm0 0"
            opacity="1"
            data-original="#e35567"
          ></path>
          <path
            fill="#fa5d72"
            d="M472.293 226.582H367.305a9.315 9.315 0 0 1-9.317-9.316v-78a9.317 9.317 0 0 1 9.317-9.317h104.988a9.317 9.317 0 0 1 9.316 9.317v78a9.315 9.315 0 0 1-9.316 9.316zm0 0"
            opacity="1"
            data-original="#fa5d72"
          ></path>
          <path
            fill="#e35567"
            d="M472.293 129.95h-17.254a10.44 10.44 0 0 1 1.875 5.98v84.675c0 2.223-.695 4.282-1.875 5.98h17.254c5.145 0 9.316-4.17 9.316-9.32v-78a9.317 9.317 0 0 0-9.316-9.316zm0 0"
            opacity="1"
            data-original="#e35567"
          ></path>
          <path
            fill="#fa5d72"
            d="M473.688 364.613H206.23a7.922 7.922 0 0 1-7.921-7.922v-89.234a7.922 7.922 0 0 1 7.921-7.922h267.457a7.922 7.922 0 0 1 7.922 7.922v89.234a7.922 7.922 0 0 1-7.921 7.922zm0 0"
            opacity="1"
            data-original="#fa5d72"
          ></path>
          <path
            fill="#e35567"
            d="M473.688 259.535h-17.79a8.99 8.99 0 0 1 .618 3.266v98.547c0 1.152-.227 2.25-.618 3.265h17.79a7.922 7.922 0 0 0 7.921-7.922v-89.234a7.922 7.922 0 0 0-7.921-7.922zm0 0"
            opacity="1"
            data-original="#e35567"
          ></path>
          <path
            fill="#fba826"
            d="M474.227 435.258H206.23a7.922 7.922 0 0 1-7.921-7.922v-17.941a7.922 7.922 0 0 1 7.921-7.922h267.997a7.922 7.922 0 0 1 7.921 7.922v17.94a7.922 7.922 0 0 1-7.921 7.923zm0 0"
            opacity="1"
            data-original="#fba826"
          ></path>
          <path
            fill="#e09721"
            d="M473.988 401.473h-23.695a8.159 8.159 0 0 1 8.16 8.16v17.465a8.159 8.159 0 0 1-8.16 8.16h23.695a8.159 8.159 0 0 0 8.16-8.16v-17.465a8.159 8.159 0 0 0-8.16-8.16zm0 0"
            opacity="1"
            data-original="#e09721"
          ></path>
          <g fill="#495663">
            <path
              d="M465.984 46.082a7.726 7.726 0 0 0-7.726-7.727h-188.18c-4.265 0-7.726 3.461-7.726 7.727s3.46 7.727 7.726 7.727h188.18c4.27 0 7.726-3.461 7.726-7.727zM32.203 143.137h101.988a7.726 7.726 0 1 0 0-15.453H32.203a7.726 7.726 0 1 0 0 15.453zM32.203 191.469h101.988a7.726 7.726 0 1 0 0-15.453H32.203a7.726 7.726 0 1 0 0 15.453zM32.203 239.8h101.988a7.726 7.726 0 1 0 0-15.453H32.203a7.726 7.726 0 1 0 0 15.454zM32.203 288.129h101.988a7.725 7.725 0 1 0 0-15.45H32.203a7.725 7.725 0 1 0 0 15.45zm0 0"
              fill="#495663"
              opacity="1"
              data-original="#495663"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
}
