import React from 'react';
import '../../assets/css/App.css';
export default function ArchiveIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 468.293 468.293"
      >
        <g>
          <path
            d="M109.804 449.487H24.976C11.182 449.487 0 438.305 0 424.511V53.278c0-13.794 11.182-24.976 24.976-24.976h84.828c13.794 0 24.976 11.182 24.976 24.976v371.234c-.001 13.793-11.183 24.975-24.976 24.975z"
            fill="#fcd462"
            data-original="#fcd462"
          ></path>
          <path
            d="M28.079 179.995V68.867c0-6.897 5.591-12.488 12.488-12.488h53.646c6.897 0 12.488 5.591 12.488 12.488v111.128c0 6.897-5.591 12.488-12.488 12.488H40.567c-6.897 0-12.488-5.591-12.488-12.488z"
            fill="#ebf0f3"
            data-original="#ebf0f3"
          ></path>
          <path
            d="M85.161 420.255H49.619c-10.345 0-18.732-8.386-18.732-18.732v-35.542c0-10.345 8.386-18.732 18.732-18.732h35.542c10.345 0 18.732 8.386 18.732 18.732v35.542c-.001 10.345-8.387 18.732-18.732 18.732z"
            fill="#64798a"
            data-original="#64798a"
          ></path>
          <path
            d="M95.515 412.546H56.204c-9.305 0-16.847-7.542-16.847-16.847v-39.311c0-2.865.782-5.522 2.044-7.884-6.161 2.506-10.514 8.532-10.514 15.593v39.311c0 9.305 7.543 16.847 16.847 16.847h39.311c6.44 0 11.968-3.654 14.803-8.963a16.768 16.768 0 0 1-6.333 1.254z"
            fill="#3a556a"
            data-original="#3a556a"
          ></path>
          <path
            d="M44.925 116.012H89.85v16.846H44.925zM44.925 82.313H89.85v16.846H44.925zM44.925 149.704H89.85v16.846H44.925z"
            fill="#64798a"
            data-original="#64798a"
          ></path>
          <path
            d="M443.317 449.487h-84.828c-13.794 0-24.976-11.182-24.976-24.976V53.278c0-13.794 11.182-24.976 24.976-24.976h84.828c13.794 0 24.976 11.182 24.976 24.976v371.234c0 13.793-11.182 24.975-24.976 24.975z"
            fill="#44c4a1"
            data-original="#44c4a1"
          ></path>
          <path
            d="M361.592 179.995V68.867c0-6.897 5.591-12.488 12.488-12.488h53.646c6.897 0 12.488 5.591 12.488 12.488v111.128c0 6.897-5.591 12.488-12.488 12.488H374.08c-6.897 0-12.488-5.591-12.488-12.488z"
            fill="#ebf0f3"
            data-original="#ebf0f3"
          ></path>
          <path
            d="M418.674 420.255h-35.542c-10.345 0-18.732-8.386-18.732-18.732v-35.542c0-10.345 8.386-18.732 18.732-18.732h35.542c10.345 0 18.732 8.386 18.732 18.732v35.542c0 10.345-8.387 18.732-18.732 18.732z"
            fill="#64798a"
            data-original="#64798a"
          ></path>
          <path
            d="M429.028 412.546h-39.311c-9.305 0-16.847-7.542-16.847-16.847v-39.311c0-2.865.782-5.522 2.044-7.884-6.161 2.506-10.514 8.532-10.514 15.593v39.311c0 9.305 7.543 16.847 16.847 16.847h39.311c6.44 0 11.968-3.654 14.803-8.963a16.76 16.76 0 0 1-6.333 1.254z"
            fill="#3a556a"
            data-original="#3a556a"
          ></path>
          <path
            d="M378.443 116.012h44.925v16.846h-44.925zM378.443 82.313h44.925v16.846h-44.925zM378.443 149.704h44.925v16.846h-44.925z"
            fill="#64798a"
            data-original="#64798a"
          ></path>
          <path
            d="m239.359 448.706-83.539-14.73c-13.584-2.395-22.654-15.349-20.259-28.933l64.464-365.594c2.395-13.584 15.349-22.654 28.933-20.259l83.539 14.73c13.584 2.395 22.654 15.349 20.259 28.933l-64.464 365.594c-2.395 13.584-15.349 22.654-28.933 20.259z"
            fill="#e56353"
            data-original="#e56353"
          ></path>
          <path
            d="m205.673 169.117 19.297-109.44c1.198-6.792 7.675-11.327 14.467-10.13l52.831 9.315c6.792 1.198 11.327 7.675 10.13 14.467l-19.297 109.44c-1.198 6.792-7.675 11.327-14.467 10.13l-52.831-9.315c-6.793-1.198-11.328-7.675-10.13-14.467z"
            fill="#ebf0f3"
            data-original="#ebf0f3"
          ></path>
          <path
            d="m220.167 415.638-35.002-6.172c-10.188-1.796-16.991-11.512-15.194-21.7l6.172-35.002c1.796-10.188 11.512-16.991 21.7-15.194l35.002 6.172c10.188 1.796 16.991 11.512 15.194 21.7l-6.172 35.002c-1.797 10.188-11.512 16.991-21.7 15.194z"
            fill="#64798a"
            data-original="#64798a"
          ></path>
          <path
            d="m231.702 409.845-38.713-6.826c-9.163-1.616-15.282-10.353-13.666-19.517l6.826-38.713c.498-2.822 1.729-5.302 3.382-7.409-6.502 1.398-11.836 6.577-13.062 13.53l-6.826 38.713c-1.616 9.163 4.503 17.901 13.666 19.517l38.713 6.826c6.342 1.118 12.421-1.52 16.135-6.257a16.759 16.759 0 0 1-6.455.136z"
            fill="#3a556a"
            data-original="#3a556a"
          ></path>
          <path
            d="M245.611 98.765h16.846v44.925h-16.846z"
            transform="rotate(99.997 254.04 121.235)"
            fill="#64798a"
            data-original="#64798a"
          ></path>
          <path
            d="M251.446 65.578h16.846v44.925h-16.846z"
            transform="rotate(99.997 259.875 88.047)"
            fill="#64798a"
            data-original="#64798a"
          ></path>
          <path
            d="M239.764 131.943h16.846v44.925h-16.846z"
            transform="rotate(99.997 248.191 154.414)"
            fill="#64798a"
            data-original="#64798a"
          ></path>
        </g>
      </svg>
    </div>
  );
}
