import React from 'react';
import OrgChart from '../../components/orgChart/HierarchyChart';

export default function Appraisals() {
  return (
    <div>
      <OrgChart />
    </div>
  );
}
