import React from 'react';
import '../../assets/css/App.css';
export default function TimeSheetIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 64 64"
        fillRule="evenodd"
      >
        <g>
          <path
            fill="#e9ecfd"
            d="M43.721 60.672a.75.75 0 0 0-.064-1.485C36.421 58.511 30.75 52.412 30.75 45c0-7.865 6.385-14.25 14.25-14.25.644 0 1.279.043 1.901.126a.75.75 0 0 0 .849-.744V12.296a4.895 4.895 0 0 0-1.435-3.463 4.895 4.895 0 0 0-3.463-1.435h-1.729a.75.75 0 0 0-.75.75v2.593a.806.806 0 0 1-1.611 0V8.148a.75.75 0 0 0-.75-.75h-4.839a.75.75 0 0 0-.75.75v2.593a.806.806 0 0 1-1.611 0V8.148a.75.75 0 0 0-.75-.75h-4.84a.75.75 0 0 0-.75.75v2.593a.806.806 0 0 1-1.611 0V8.148a.75.75 0 0 0-.75-.75h-4.839a.75.75 0 0 0-.75.75v2.593a.806.806 0 0 1-1.612 0V8.148a.75.75 0 0 0-.75-.75h-1.728a4.895 4.895 0 0 0-3.463 1.435 4.895 4.895 0 0 0-1.435 3.463v43.556c0 1.299.516 2.545 1.435 3.463a4.895 4.895 0 0 0 3.463 1.435h30.42c.293 0 .584-.026.869-.078z"
            opacity="1"
            data-original="#e9ecfd"
          ></path>
          <path
            fill="#ced5f9"
            d="M36.209 33.788C30.912 36.326 27.25 41.739 27.25 48c0 5.24 2.564 9.886 6.506 12.75h9.096c.293 0 .584-.026.869-.078a.75.75 0 0 0-.064-1.485C36.421 58.511 30.75 52.412 30.75 45c0-4.549 2.136-8.603 5.459-11.212z"
            opacity="1"
            data-original="#ced5f9"
          ></path>
          <path
            fill="#55a1ff"
            d="M15.642 25.898h24a.75.75 0 0 0 0-1.5h-24a.75.75 0 0 0 0 1.5zM15.642 33.898h20.167a.75.75 0 0 0 0-1.5H15.642a.75.75 0 0 0 0 1.5zM15.642 41.898H27a.75.75 0 0 0 0-1.5H15.642a.75.75 0 0 0 0 1.5z"
            opacity="1"
            data-original="#55a1ff"
          ></path>
          <path
            fill="#303c65"
            d="M33.383 53.25H7.534v2.602c0 1.299.516 2.545 1.435 3.463a4.895 4.895 0 0 0 3.463 1.435h30.42c.293 0 .584-.026.869-.078a.75.75 0 0 0-.064-1.485 14.241 14.241 0 0 1-10.274-5.937z"
            opacity="1"
            data-original="#303c65"
          ></path>
          <path
            fill="#2e344b"
            d="M33.383 53.25h-5.235a15.804 15.804 0 0 0 5.608 7.5h9.096c.293 0 .584-.026.869-.078a.75.75 0 0 0-.064-1.485l-.049-.004-.078-.008-.042-.004-.042-.005-.039-.004-.051-.006-.057-.007-.064-.007-.042-.006-.042-.005-.039-.005-.053-.007-.054-.008-.063-.009-.042-.006-.042-.006-.039-.006-.055-.009-.072-.011-.066-.011-.058-.01-.04-.007-.056-.01-.07-.013-.082-.015-.041-.008-.04-.008-.056-.011-.068-.014-.041-.008-.041-.009-.036-.007-.09-.02-.059-.013-.059-.014-.041-.009-.036-.009-.09-.021-.058-.014-.059-.015-.04-.01-.035-.01-.091-.023-.056-.015-.059-.016-.039-.011-.036-.01-.091-.026-.072-.021-.04-.012-.031-.009-.136-.041-.07-.022-.039-.013-.03-.009-.137-.045-.068-.023-.025-.008-.226-.079v-.001a14.29 14.29 0 0 1-6.801-5.164z"
            opacity="1"
            data-original="#2e344b"
          ></path>
          <path
            fill="#55a1ff"
            d="M7.534 15.75H47.75v-3.454a4.895 4.895 0 0 0-1.435-3.463 4.895 4.895 0 0 0-3.463-1.435h-1.729a.75.75 0 0 0-.75.75v2.593a.806.806 0 0 1-1.611 0V8.148a.75.75 0 0 0-.75-.75h-4.839a.75.75 0 0 0-.75.75v2.593a.806.806 0 0 1-1.611 0V8.148a.75.75 0 0 0-.75-.75h-4.84a.75.75 0 0 0-.75.75v2.593a.806.806 0 0 1-1.611 0V8.148a.75.75 0 0 0-.75-.75h-4.839a.75.75 0 0 0-.75.75v2.593a.806.806 0 0 1-1.612 0V8.148a.75.75 0 0 0-.75-.75h-1.728a4.895 4.895 0 0 0-3.463 1.435 4.895 4.895 0 0 0-1.435 3.463z"
            opacity="1"
            data-original="#55a1ff"
          ></path>
          <circle
            cx="45"
            cy="45"
            r="15.75"
            fill="#446aa9"
            opacity="1"
            data-original="#446aa9"
          ></circle>
          <circle
            cx="45"
            cy="45"
            r="11.25"
            fill="#e9ecfe"
            opacity="1"
            data-original="#e9ecfe"
          ></circle>
          <path
            fill="#f05558"
            d="m42.769 45.026-.002.062c0 1.216.987 2.204 2.204 2.204a.767.767 0 0 0 .082-.005l5.2-.57a1.63 1.63 0 0 0-.003-3.258l-3.236-.355-.414-5.031a1.63 1.63 0 0 0-3.259.011l-.572 6.942z"
            opacity="1"
            data-original="#f05558"
          ></path>
          <g fill="#303c65">
            <path
              d="M13.41 5.556v5.185a2.307 2.307 0 0 0 4.612 0V5.556a2.307 2.307 0 0 0-4.612 0zM21.361 5.556v5.185a2.307 2.307 0 0 0 4.611 0V5.556a2.307 2.307 0 0 0-4.611 0zM29.312 5.556v5.185a2.306 2.306 0 0 0 4.611 0V5.556a2.307 2.307 0 0 0-4.611 0zM37.262 5.556v5.185a2.307 2.307 0 0 0 4.611 0V5.556a2.307 2.307 0 0 0-4.611 0z"
              fill="#303c65"
              opacity="1"
              data-original="#303c65"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
}
