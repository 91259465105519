import React from 'react';
import '../../assets/css/App.css';
export default function MeetingRoomIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 73 73"
      >
        <g>
          <path
            fill="#2d77fc"
            d="M67.875 53.815v3.21a5.656 5.656 0 0 1-5.656 5.657H48.698v-8.867h19.177z"
            opacity="1"
            data-original="#2d77fc"
          ></path>
          <path
            fill="#29265d"
            d="m24.297 62.814-.122-8.672H5l.125 3.213a5.652 5.652 0 0 0 4.198 5.459h14.974z"
            opacity="1"
            data-original="#29265d"
          ></path>
          <path
            fill="#8c382d"
            d="M59.665 62.814v-.682l-8.187-29.626h-29.88l-8.186 29.626v.682z"
            opacity="1"
            data-original="#8c382d"
          ></path>
          <path
            fill="#2d77fc"
            d="M29.289 18.678H43.14v14.483H29.289z"
            opacity="1"
            data-original="#2d77fc"
          ></path>
          <path
            fill="#f7f8f8"
            d="m40.637 18.668-3.548 5.437-.876 1.342-.875-1.342-3.545-5.437z"
            opacity="1"
            data-original="#f7f8f8"
          ></path>
          <path
            fill="#feb630"
            d="m37.089 24.105-.876 1.342-.875-1.342.287-2.79.034-.349h1.108l.032.303z"
            opacity="1"
            data-original="#feb630"
          ></path>
          <path
            fill="#feb630"
            d="m37.33 20.661-.667.761a.63.63 0 0 1-.948 0l-.667-.76a.63.63 0 0 1 .474-1.046h1.333a.63.63 0 0 1 .474 1.045z"
            opacity="1"
            data-original="#feb630"
          ></path>
          <path
            fill="#2d77fc"
            d="M29.475 18.746a2.639 2.639 0 0 1 1.558 3.424l-3.39 9.319c-.42 1.373-1.635 2.08-2.715 1.58-1.085-.505-1.618-2.042-1.187-3.419l3.404-8.533c.222-.704.698-1.409 1.15-1.862.548-.55.891-.567.994-.576z"
            opacity="1"
            data-original="#2d77fc"
          ></path>
          <path
            fill="#f9b29c"
            d="M38.187 15.81v2.864l-1.97.942-1.978-.942V15.81z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#e29a81"
            d="M38.187 15.81v1.507a3.794 3.794 0 0 1-1.867.488 3.794 3.794 0 0 1-2.08-.617V15.81z"
            opacity="1"
            data-original="#e29a81"
          ></path>
          <path
            fill="#e6e8e8"
            d="m39.399 18.677-1.546 2.086-1.59-1.146 1.974-1.72 1.133.776z"
            opacity="1"
            data-original="#e6e8e8"
          ></path>
          <path
            fill="#e6e8e8"
            d="m33.127 18.677 1.546 2.086 1.59-1.146-1.975-1.72-1.132.776z"
            opacity="1"
            data-original="#e6e8e8"
          ></path>
          <path
            fill="#2d77fc"
            d="M47.566 32.948c-1.079.502-2.295-.206-2.715-1.58l-3.1-9.456a2.639 2.639 0 0 1 1.386-3.244c1.194.074 2.255 1.036 2.699 2.45l2.916 8.411c.433 1.378-.1 2.914-1.186 3.419z"
            opacity="1"
            data-original="#2d77fc"
          ></path>
          <path
            fill="#f9b29c"
            d="m40.475 29.117-.768.786-.48-.598a.698.698 0 0 1-.053-.744c.092-.172.244-.262.4-.262a.43.43 0 0 1 .324.157zM31.303 29.117l1.285.786-.038-.598c.159-.2.18-.513.054-.744-.092-.172-.244-.262-.4-.262a.43.43 0 0 0-.324.157z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#2d77fc"
            d="M48.892 30.345c-.115-1.424-1.15-2.459-2.304-2.303l-6.05 1.075-.063 4.044h6.547c1.147-.14 1.985-1.402 1.87-2.816z"
            opacity="1"
            data-original="#2d77fc"
          ></path>
          <path
            fill="#f9b29c"
            d="M40.475 29.117v4.044l-2.428-.047c-.628-.011-1.155-.586-1.244-1.35a1.671 1.671 0 0 1-.013-.214c0-.833.537-1.541 1.229-1.573l1.688-.074z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#2d77fc"
            d="M23.602 30.465c.115-1.424 1.15-2.458 2.304-2.303l5.395.954v4.046l-5.829.12c-1.147-.141-1.985-1.403-1.87-2.817z"
            opacity="1"
            data-original="#2d77fc"
          ></path>
          <path
            fill="#125ac4"
            d="m40.507 28.939 3.44-.455.041.312-3.44.455zM31.287 29.273l-2.697-.236.053-.402 2.672.324z"
            opacity="1"
            data-original="#125ac4"
          ></path>
          <path
            fill="#f9b29c"
            d="M31.303 29.117v4.044l2.428-.047c.628-.011 1.155-.586 1.244-1.35.01-.073.012-.144.012-.214 0-.833-.536-1.541-1.228-1.573l-1.171-.074z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#125ac4"
            d="m28.59 29.052 2.59-6.826-.236-.43-2.56 7.158zM44.22 28.782l-2.32-6.92-.298.1 2.274 6.875z"
            opacity="1"
            data-original="#125ac4"
          ></path>
          <path
            fill="#29275c"
            d="M43.812 33.23H28.963a1.241 1.241 0 0 1-1.24-1.242c0-.329.266-.595.595-.595h16.14c.328 0 .595.266.595.595 0 .686-.556 1.242-1.241 1.242z"
            opacity="1"
            data-original="#29275c"
          ></path>
          <path
            fill="#393982"
            d="M42.383 33.216h-12.24a.787.787 0 0 1-.773-.633l-1.775-8.882a.787.787 0 0 1 .773-.941h15.79c.496 0 .87.454.772.941l-1.775 8.882a.787.787 0 0 1-.772.633z"
            opacity="1"
            data-original="#393982"
          ></path>
          <circle
            cx="36.215"
            cy="28.624"
            r="1.254"
            fill="#29275c"
            opacity="1"
            data-original="#29275c"
          ></circle>
          <path
            fill="#04051615"
            d="M37.522 28.713c0 .693-.562 1.254-1.254 1.254-.487 0-.903-.276-1.11-.68.176.093.373.146.58.146.692 0 1.253-.562 1.253-1.254 0-.206-.052-.404-.145-.575.404.206.676.627.676 1.11z"
            opacity="1"
            data-original="#04051615"
          ></path>
          <ellipse
            cx="40.06"
            cy="12.63"
            fill="#f9b29c"
            rx=".936"
            ry="1.092"
            opacity="1"
            data-original="#f9b29c"
          ></ellipse>
          <path
            fill="#e29a81"
            d="M40.434 13.122a.079.079 0 0 1-.052-.138.477.477 0 0 0 .174-.354.477.477 0 0 0-.174-.355.079.079 0 0 1 .104-.118.63.63 0 0 1 .228.473c0 .175-.081.342-.227.472a.08.08 0 0 1-.053.02z"
            opacity="1"
            data-original="#e29a81"
          ></path>
          <ellipse
            cx="32.633"
            cy="12.63"
            fill="#f9b29c"
            rx=".936"
            ry="1.092"
            opacity="1"
            data-original="#f9b29c"
          ></ellipse>
          <path
            fill="#e29a81"
            d="M32.258 13.122a.079.079 0 0 0 .053-.138.477.477 0 0 1-.174-.354c0-.13.061-.256.173-.355a.079.079 0 0 0-.104-.118.63.63 0 0 0-.227.473c0 .175.08.342.227.472a.08.08 0 0 0 .052.02z"
            opacity="1"
            data-original="#e29a81"
          ></path>
          <path
            fill="#f9b29c"
            d="M40.186 11.439v2.01a3.867 3.867 0 0 1-7.734 0v-2.01a3.864 3.864 0 0 1 3.867-3.867c1.069 0 2.034.432 2.735 1.132.7.7 1.132 1.666 1.132 2.735z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#15144f"
            d="M35.142 12.37c0 .268-.147.484-.33.484-.184 0-.335-.216-.335-.484s.15-.488.334-.488c.184 0 .33.22.33.488zM38.16 12.37c0 .268-.147.484-.33.484-.184 0-.334-.216-.334-.484s.15-.488.333-.488c.184 0 .331.22.331.488z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#ffffff"
            d="M34.97 14.456c-.001.02-.006.039-.006.059a1.355 1.355 0 1 0 2.71 0c0-.02-.005-.039-.006-.059z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#15144f"
            d="m40.241 12.137-.053.047s-.47.053-.47 0c0-.054-.12-.892-.12-.892s-.838-1.166-.235-2.239c0 0-2.539 1.407-5.87.093 0 0-.453.375-.308 1.394.07.5-.156 1.228-.37 1.985h-.362l-.295-.837s-1.428-2.628.18-3.968c0 0-.569-2.145 1.83-2.91l.396 1.267s.945-1.488 2.829-1.568c1.89-.087 1.481 1.629 1.481 1.629s1.2-.563 1.662.067c.456.63-.295 1.273-.295 1.273s.604.436.832 1.448c.234 1.012-.832 3.21-.832 3.21z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#f9b29c"
            d="M58.288 40.253s-1.317 4.568-4.842 4.568c-3.532 0-15.28-.748-15.28-.748 0-.978.368-1.87.972-2.547a3.786 3.786 0 0 1 2.417-1.259l10.129-.87s1.057-2.029 1.122-2.316c.007-.065.022-.13.029-.187z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#ed8768"
            d="M58.288 40.253s-1.317 4.568-4.842 4.568c-3.532 0-15.28-.748-15.28-.748 0-.439.073-.863.217-1.252 3.704.223 10.006.576 12.43.576 3.525 0 4.842-4.568 4.842-4.568l-2.849-1.756c.007-.064.022-.122.029-.18z"
            opacity="1"
            data-original="#ed8768"
          ></path>
          <path
            fill="#792f26"
            d="M13.412 62.132h46.253v.682H13.412z"
            opacity="1"
            data-original="#792f26"
          ></path>
          <path
            fill="#f9b29c"
            d="M29.862 32.922a.899.899 0 0 1-.48.799l-1.904.997s2.08 1.543.58 3.544l-.813 1.168-.007.028-3.283 4.145a3.495 3.495 0 0 1-5.022.488l-2.71-2.327 3.969-3.919.877.729a1.14 1.14 0 0 0 1.641-.205l1.252-1.712.064-.474a3.724 3.724 0 0 1 1.952-2.71l2.575-1.344a.9.9 0 0 1 1.21.382c.07.135.1.276.1.41z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#ed8768"
            d="m28.056 38.264-.813 1.168-.007.026-3.28 4.146a3.496 3.496 0 0 1-5.026.486l-2.71-2.323.06-.059 2.178 1.877a3.496 3.496 0 0 0 5.026-.492l3.28-4.14.007-.033.813-1.168c.932-1.24.486-2.303.013-2.933.414.361 1.745 1.739.46 3.445z"
            opacity="1"
            data-original="#ed8768"
          ></path>
          <path
            fill="#02b07e"
            d="M16.39 33.615c.719.348 2.639 2.508 4.344 4.343l-4.157 4.375z"
            opacity="1"
            data-original="#02b07e"
          ></path>
          <path
            fill="#03c88b"
            d="m17.777 36.579-.61 11.98-1.544 5.589-10.498.003V35.277a2.597 2.597 0 0 1 2.596-2.604h6.15a3.905 3.905 0 0 1 3.906 3.906z"
            opacity="1"
            data-original="#03c88b"
          ></path>
          <path
            fill="#02b07e"
            d="m17.777 46.397-.61 2.161-1.544 5.59-10.498.003V36.349a5.544 5.544 0 0 1 1.086-.317l1.439 1.316c1.237 3.395 5.35 6.344 8.458 8.193.54.324 1.094.604 1.669.856z"
            opacity="1"
            data-original="#02b07e"
          ></path>
          <path
            fill="#f9b29c"
            d="M8.312 43.986s1.313 4.572 4.84 4.572c3.528 0 15.277-.753 15.277-.753a3.827 3.827 0 0 0-3.39-3.802l-10.123-.875s-1.058-2.026-1.121-2.316a3.15 3.15 0 0 1-.035-.187z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#03c88b"
            d="m10.593 34.508 3.263 6.193c.371.474-5.39 4.989-5.762 4.515-1.102-1.269-2.797-4.545-3.398-6.999-.51-1.85-.061-4.2 1.779-5.198.753-.408 1.62-.378 2.365-.111a3.13 3.13 0 0 1 1.753 1.6z"
            opacity="1"
            data-original="#03c88b"
          ></path>
          <path
            fill="#02b07e"
            d="M13.726 41.187a.19.19 0 0 1-.134-.346l.426-.284-2.148-3.826a.19.19 0 0 1 .331-.186l2.235 3.979a.19.19 0 0 1-.06.25l-.572.383a.187.187 0 0 1-.078.03z"
            opacity="1"
            data-original="#02b07e"
          ></path>
          <path
            fill="#d63554"
            d="M56.608 33.282c-.718.348-2.639 2.508-4.344 4.344L56.421 42z"
            opacity="1"
            data-original="#d63554"
          ></path>
          <path
            fill="#15144f"
            d="M55.361 21.791c-.01.073-.26 5.093-.933 6.497-.673 1.403 4.865 5.366 6.443 4.052 1.578-1.314 1.097-7.06 1.097-7.06l-1.523-2.156z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#f24e66"
            d="M55.221 36.246V48.44l2.165 5.38h10.489V34.945a2.597 2.597 0 0 0-2.597-2.604h-6.15a3.905 3.905 0 0 0-3.907 3.906z"
            opacity="1"
            data-original="#f24e66"
          ></path>
          <path
            fill="#d63554"
            d="M55.221 46.066v2.374l2.057 5.38h10.597V36.017a5.544 5.544 0 0 0-1.086-.317l-1.439 1.317c-1.237 3.395-5.352 6.345-8.46 8.194a14.6 14.6 0 0 1-1.669.856z"
            opacity="1"
            data-original="#d63554"
          ></path>
          <path
            fill="#f9b29c"
            d="M64.687 43.655s-1.312 4.572-4.84 4.572c-3.53 0-15.28-.753-15.28-.753a3.827 3.827 0 0 1 3.392-3.803l10.124-.875s1.058-2.026 1.12-2.316c.014-.061.026-.123.035-.187z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#f24e66"
            d="m62.407 34.175-3.264 6.194c-.372.474 5.391 4.99 5.762 4.516 1.103-1.27 2.797-4.546 3.399-7 .51-1.85.061-4.2-1.78-5.199-.753-.409-1.62-.378-2.365-.111a3.13 3.13 0 0 0-1.752 1.6z"
            opacity="1"
            data-original="#f24e66"
          ></path>
          <path
            fill="#d63554"
            d="M59.273 40.855a.19.19 0 0 0 .134-.346l-.426-.284 2.148-3.827a.19.19 0 0 0-.331-.186l-2.235 3.98a.19.19 0 0 0 .06.25l.572.383c.024.016.05.026.078.03z"
            opacity="1"
            data-original="#d63554"
          ></path>
          <path
            fill="#f9b29c"
            d="m64.126 32.342-5.506 2.653.927-2.673z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#f9b29c"
            d="M59.548 27.452h4.575v4.888h-4.575z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#ed8768"
            d="M59.548 26.509v4.266s2.023 1.045 4.575.653v-4.92z"
            opacity="1"
            data-original="#ed8768"
          ></path>
          <path
            fill="#f9b29c"
            d="M56.117 24.391v2.754a4 4 0 0 0 4.002 4.001 4.003 4.003 0 0 0 4.002-4.001V24.39a4.003 4.003 0 0 0-6.835-2.828 4.002 4.002 0 0 0-1.169 2.828z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <ellipse
            cx="60.311"
            cy="25.724"
            fill="#15144f"
            rx=".373"
            ry=".492"
            opacity="1"
            data-original="#15144f"
          ></ellipse>
          <ellipse
            cx="57.654"
            cy="25.724"
            fill="#15144f"
            rx=".373"
            ry=".492"
            opacity="1"
            data-original="#15144f"
          ></ellipse>
          <path
            fill="#ffffff"
            d="M60.565 27.974c.001.022.007.043.007.065a1.518 1.518 0 1 1-3.036 0c0-.022.006-.043.007-.065z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#15144f"
            d="M68.232 29.996c-.933 2.693-3.88 2.397-4.106 2.344v-7.163c-4.52-2.2-5.326-3.3-5.472-3.646 1.173-.827 4.039-2.987 4.039-2.987 4.36-1.18 4.792 3.48 4.512 4.726-.28 1.253 1.027 6.726 1.027 6.726z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#15144f"
            d="M54.26 20.846c0 1.835 2.243 3.316 5.012 3.316 2.182 0 4.037-.92 4.728-2.207.184-.348.283-.721.283-1.109 0-.82-.452-1.57-1.198-2.147-.005 0-.01-.005-.015-.01-.92-.711-2.277-1.159-3.798-1.159a6.4 6.4 0 0 0-.825.05c-1.387.15-2.576.676-3.331 1.412-.537.532-.855 1.168-.855 1.854z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#f9b29c"
            d="M64.124 25.172a1.338 1.338 0 0 1 0 2.674zM14.269 35.675l-5.85-3.003h5.06z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#15144f"
            d="m17.691 19.561.01 3.6c.002.444-.18.868-.5 1.174l-1.573-3.053.043-1.77z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#f9b29c"
            d="M8.418 27.268h5.062v5.404H8.418z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#ed8768"
            d="M13.481 26.225v4.72s-2.238 1.156-5.062.722v-5.442z"
            opacity="1"
            data-original="#ed8768"
          ></path>
          <path
            fill="#f9b29c"
            d="m17.26 24.114-.075 3.045a4.425 4.425 0 0 1-4.533 4.318 4.429 4.429 0 0 1-4.318-4.533l.074-3.045a4.429 4.429 0 0 1 7.635-2.944 4.427 4.427 0 0 1 1.217 3.159z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#15144f"
            d="M15.114 25.396c-.009.371.17.677.396.683.23.005.424-.292.433-.663.01-.377-.17-.682-.4-.688-.226-.006-.42.291-.429.668zM12.177 25.324c-.009.372.17.678.396.683.225.006.419-.291.428-.663.009-.376-.17-.682-.396-.687-.226-.006-.419.29-.428.667z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#ffffff"
            d="M12.244 27.957c-.002.024-.009.047-.01.071a1.68 1.68 0 1 0 3.358.082c.001-.025-.005-.048-.005-.072z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#15144f"
            d="M19.083 19.91s.006 4.57-9.076 1.597c0 0 .301 1.148-.96 1.739l-.029 1.807a.609.609 0 0 1-.653.591l.017-.88-.603.494-.562.455-.148.125s-2.569-2.592-1.233-6.81c.556-1.75 1.489-2.119 2.926-1.795 0 0-.409-2.2 4.07-1.978 1.16.057 2.063.67 2.944 1.285.34.238.682.471 1.028.676.557.33 1.137.58 1.802.602 0 0-.006 1.28-.745 1.444 0 0 .21.92 1.222.648z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#f9b29c"
            d="M8.45 24.768a1.39 1.39 0 0 0-.068 2.78z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#ff8e2e"
            d="M27.333 68.494v-17.31h-.54c-1.037 0-1.986.339-2.757.908 0 0-.006 0-.006.006a4.62 4.62 0 0 0-1.859 3.707v12.69h5.162z"
            opacity="1"
            data-original="#ff8e2e"
          ></path>
          <path
            fill="#ff8e2e"
            d="M48.045 68.494V52.778c0-.048 0-.096-.007-.13a4.227 4.227 0 0 0-.606-.552c0-.006-.007-.006-.007-.006a4.649 4.649 0 0 0-2.76-.907H26.789c-1.036 0-1.983.34-2.753.907 0 0-.007 0-.007.006a4.226 4.226 0 0 0-.607.552c-.006.034-.006.082-.006.13v15.716z"
            opacity="1"
            data-original="#ff8e2e"
          ></path>
          <path
            fill="#ff8e2e"
            d="M27.333 68.494v-17.31h-.54c-1.037 0-1.986.339-2.757.908 0 0-.006 0-.006.006a4.224 4.224 0 0 0-.61.547 4.591 4.591 0 0 0-1.249 3.16v12.69h5.162z"
            opacity="1"
            data-original="#ff8e2e"
          ></path>
          <path
            fill="#f67112"
            d="M27.805 68.494v-8.753a.237.237 0 0 0-.236-.236.233.233 0 0 0-.236.236v8.753z"
            opacity="1"
            data-original="#f67112"
          ></path>
          <path
            fill="#ff8e2e"
            d="M49.289 68.494v-12.69c0-1.519-.73-2.86-1.859-3.706 0-.006-.006-.006-.006-.006a4.617 4.617 0 0 0-2.756-.909h-.541v17.311h5.162z"
            opacity="1"
            data-original="#ff8e2e"
          ></path>
          <path
            fill="#ff8e2e"
            d="M49.289 68.494v-12.69c0-1.225-.472-2.33-1.249-3.159a4.226 4.226 0 0 0-.61-.547c0-.006-.006-.006-.006-.006a4.617 4.617 0 0 0-2.756-.909h-.541v17.311h5.162z"
            opacity="1"
            data-original="#ff8e2e"
          ></path>
          <path
            fill="#f67112"
            d="M44.127 68.494v-8.753a.233.233 0 0 0-.236-.236.237.237 0 0 0-.236.236v8.753z"
            opacity="1"
            data-original="#f67112"
          ></path>
          <path
            fill="#ffcccb"
            d="M41.479 40.837v1.59a5.742 5.742 0 0 1-5.748 5.748 5.745 5.745 0 0 1-5.749-5.637v-1.701c0-3.172 2.57-5.756 5.749-5.756 1.585 0 3.02.65 4.067 1.689a5.727 5.727 0 0 1 1.68 4.067z"
            opacity="1"
            data-original="#ffcccb"
          ></path>
          <path
            fill="#f9b29c"
            d="M39.657 51.183h-7.854l1.072-5.073h5.71z"
            opacity="1"
            data-original="#f9b29c"
          ></path>
          <path
            fill="#f67112"
            d="M40.436 51.183h-9.412c0-.677.549-1.226 1.226-1.226h6.96c.677 0 1.226.549 1.226 1.226z"
            opacity="1"
            data-original="#f67112"
          ></path>
          <path
            fill="#15144f"
            d="M35.775 49.047c-7.116 0-7.992-12.194-6.74-13.795 2.21-2.815 11.269-2.815 13.48 0 1.252 1.601-.412 13.795-6.74 13.795z"
            opacity="1"
            data-original="#15144f"
          ></path>
          <path
            fill="#eff6ff"
            d="M45.888 10.255a3.59 3.59 0 0 0 3.596 3.585h6.564a3.574 3.574 0 0 0 3.496-2.779c.528-2.208-1.163-4.396-3.496-4.396h-6.564a3.59 3.59 0 0 0-3.596 3.59z"
            opacity="1"
            data-original="#eff6ff"
          ></path>
          <path
            fill="#eff6ff"
            d="m51.891 13.84-2.479 1.45v-1.45z"
            opacity="1"
            data-original="#eff6ff"
          ></path>
          <path
            fill="#d9e5f1"
            d="M57.361 9.109a.48.48 0 0 1-.478.478h-8.24a.477.477 0 0 1-.478-.478c0-.268.211-.478.478-.478h8.24c.262 0 .478.21.478.478zM54.108 11.033a.48.48 0 0 1-.478.478h-4.987a.477.477 0 0 1-.478-.478c0-.267.211-.478.478-.478h4.987c.262 0 .478.21.478.478z"
            opacity="1"
            data-original="#d9e5f1"
          ></path>
          <path
            fill="#eff6ff"
            d="M17.26 14.122a3.314 3.314 0 0 0 3.32 3.308h6.059a3.308 3.308 0 0 0 3.313-3.308 3.314 3.314 0 0 0-3.313-3.314h-6.06a3.314 3.314 0 0 0-3.318 3.314zM22.802 17.432l-2.288 1.337v-1.337z"
            opacity="1"
            data-original="#eff6ff"
          ></path>
          <path
            fill="#d9e5f1"
            d="M27.85 13.064c0 .242-.199.441-.44.441h-7.606a.44.44 0 0 1-.441-.441c0-.247.194-.441.441-.441h7.605a.44.44 0 0 1 .442.44zM24.848 14.84c0 .242-.2.441-.441.441h-4.603a.44.44 0 0 1-.441-.44c0-.248.194-.442.441-.442h4.603a.44.44 0 0 1 .44.441z"
            opacity="1"
            data-original="#d9e5f1"
          ></path>
        </g>
      </svg>
    </div>
  );
}
