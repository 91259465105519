import React from 'react';
import '../../assets/css/App.css';
export default function CalenderIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 512 511"
      >
        <g>
          <path
            fill="#ff9f60"
            d="M490 245.5c0 135.309-109.691 245-245 245S0 380.809 0 245.5 109.691.5 245 .5s245 109.691 245 245zm0 0"
            opacity="1"
            data-original="#ff9f60"
          ></path>
          <path
            fill="#eef6ff"
            d="M298.965 356.48c0-58.457 47.558-106.015 106.02-106.015 25.667 0 49.234 9.172 67.597 24.41V116.5H39.418v262.3a246.335 246.335 0 0 0 39.945 47.212h245.653c-16.22-18.625-26.051-42.953-26.051-69.532zm0 0"
            opacity="1"
            data-original="#eef6ff"
          ></path>
          <path
            fill="#d9eafc"
            d="M39.418 116.5v262.3c8.914 13.72 19.184 26.473 30.582 38.11V116.5zm0 0"
            opacity="1"
            data-original="#d9eafc"
          ></path>
          <path
            fill="#ea2a3d"
            d="M462.582 55.5H49.418c-5.5 0-10 4.5-10 10v52h433.164v-52c0-5.5-4.5-10-10-10zm0 0"
            opacity="1"
            data-original="#ea2a3d"
          ></path>
          <path
            fill="#b7092b"
            d="M80 55.5H49.418c-5.5 0-10 4.5-10 10v52H70v-52c0-5.5 4.5-10 10-10zm0 0"
            opacity="1"
            data-original="#b7092b"
          ></path>
          <path
            fill="#91e0e8"
            d="M150.578 189.79c0 2.75-2.25 5-5 5H74.484c-2.75 0-5-2.25-5-5v-42.263c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zM247.89 189.79c0 2.75-2.25 5-5 5h-71.093c-2.75 0-5-2.25-5-5v-42.263c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zM345.203 189.79c0 2.75-2.25 5-5 5H269.11c-2.75 0-5-2.25-5-5v-42.263c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zm0 0"
            opacity="1"
            data-original="#91e0e8"
          ></path>
          <path
            fill="#ffc987"
            d="M442.516 189.79c0 2.75-2.25 5-5 5h-71.094c-2.75 0-5-2.25-5-5v-42.263c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zm0 0"
            opacity="1"
            data-original="#ffc987"
          ></path>
          <path
            fill="#91e0e8"
            d="M150.578 256.465c0 2.75-2.25 5-5 5H74.484c-2.75 0-5-2.25-5-5v-42.262c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zM247.89 256.465c0 2.75-2.25 5-5 5h-71.093c-2.75 0-5-2.25-5-5v-42.262c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zM345.203 256.465c0 2.75-2.25 5-5 5H269.11c-2.75 0-5-2.25-5-5v-42.262c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zm0 0"
            opacity="1"
            data-original="#91e0e8"
          ></path>
          <path
            fill="#ffc987"
            d="M404.98 250.465c13.18 0 25.801 2.426 37.45 6.836a4.89 4.89 0 0 0 .086-.836v-42.262c0-2.75-2.25-5-5-5h-71.094c-2.75 0-5 2.25-5 5v42.262c0 1.101.371 2.11.976 2.937 13.043-5.742 27.446-8.937 42.582-8.937zm0 0"
            opacity="1"
            data-original="#ffc987"
          ></path>
          <path
            fill="#91e0e8"
            d="M150.578 323.145c0 2.75-2.25 5-5 5H74.484c-2.75 0-5-2.25-5-5v-42.262c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zM247.89 323.145c0 2.75-2.25 5-5 5h-71.093c-2.75 0-5-2.25-5-5v-42.262c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zM336.191 275.883H269.11c-2.75 0-5 2.25-5 5v42.262c0 2.75 2.25 5 5 5h33.707c5.727-20.618 17.54-38.727 33.375-52.262zM150.578 389.82c0 2.75-2.25 5-5 5H74.484c-2.75 0-5-2.25-5-5V347.56c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zM247.89 389.82c0 2.75-2.25 5-5 5h-71.093c-2.75 0-5-2.25-5-5V347.56c0-2.75 2.25-5 5-5h71.094c2.75 0 5 2.25 5 5zM298.965 356.48c0-4.718.312-9.363.914-13.921h-30.77c-2.75 0-5 2.25-5 5v42.261c0 2.75 2.25 5 5 5h37.032c-4.63-11.894-7.176-24.824-7.176-38.34zm0 0"
            opacity="1"
            data-original="#91e0e8"
          ></path>
          <g fill="#c3ddf4">
            <path
              d="M76 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM112 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM148 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM184 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM220 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM256 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM292 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM328 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM364 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM400 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zM436 31.867a7.497 7.497 0 0 0-7.5 7.5v41.27c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-41.27c0-4.144-3.355-7.5-7.5-7.5zm0 0"
              fill="#c3ddf4"
              opacity="1"
              data-original="#c3ddf4"
            ></path>
          </g>
          <path
            fill="#1a2b63"
            d="M512 356.48c0 59.106-47.914 107.02-107.02 107.02-59.101 0-107.015-47.914-107.015-107.02 0-59.101 47.914-107.015 107.015-107.015 59.106 0 107.02 47.914 107.02 107.015zm0 0"
            opacity="1"
            data-original="#1a2b63"
          ></path>
          <path
            fill="#ffffff"
            d="M404.98 435.5c-43.57 0-79.015-35.445-79.015-79.02 0-43.57 35.445-79.015 79.015-79.015 43.575 0 79.02 35.445 79.02 79.015 0 43.575-35.445 79.02-79.02 79.02zm0 0"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#eef6ff"
            d="M340.969 356.48c0-41.039 31.449-74.859 71.515-78.648a79.424 79.424 0 0 0-7.5-.367c-43.57 0-79.02 35.445-79.02 79.02 0 43.57 35.446 79.015 79.02 79.015 2.528 0 5.028-.133 7.5-.363-40.066-3.793-71.515-37.614-71.515-78.657zm0 0"
            opacity="1"
            data-original="#eef6ff"
          ></path>
          <path
            fill="#ffdd8d"
            d="M404.98 277.465v62.66c9.024 0 16.36 7.336 16.36 16.355h62.656c.004-43.57-35.441-79.015-79.016-79.015zm0 0"
            opacity="1"
            data-original="#ffdd8d"
          ></path>
          <path
            fill="#0f9390"
            d="M404.98 340.125c2.708 0 5.254.668 7.5 1.836V300.59c0-4.145-3.355-7.5-7.5-7.5a7.5 7.5 0 0 0-7.5 7.5v41.37a16.228 16.228 0 0 1 7.5-1.835zM448.32 348.98h-28.816a16.228 16.228 0 0 1 0 15h28.816a7.5 7.5 0 1 0 0-15zm0 0"
            opacity="1"
            data-original="#0f9390"
          ></path>
          <path
            fill="#2bb381"
            d="M404.98 373.84c-9.57 0-17.355-7.785-17.355-17.356 0-9.574 7.785-17.359 17.355-17.359 9.575 0 17.36 7.785 17.36 17.36 0 9.57-7.785 17.355-17.36 17.355zm0 0"
            opacity="1"
            data-original="#2bb381"
          ></path>
          <path
            fill="#60d882"
            d="M404.98 428a7.5 7.5 0 0 1-7.5-7.5v-8a7.5 7.5 0 0 1 7.5-7.5c4.145 0 7.5 3.355 7.5 7.5v8c0 4.145-3.355 7.5-7.5 7.5zM359.715 409.25a7.486 7.486 0 0 1-5.305-2.195 7.506 7.506 0 0 1 .004-10.61l5.656-5.656a7.5 7.5 0 0 1 10.606.004 7.497 7.497 0 0 1 0 10.605l-5.656 5.657a7.473 7.473 0 0 1-5.305 2.195zM348.965 363.984h-8a7.505 7.505 0 0 1-7.5-7.504 7.501 7.501 0 0 1 7.5-7.496h8a7.5 7.5 0 0 1 7.5 7.5 7.5 7.5 0 0 1-7.5 7.5zM365.371 324.375a7.477 7.477 0 0 1-5.305-2.2l-5.656-5.655a7.5 7.5 0 0 1 .004-10.606 7.497 7.497 0 0 1 10.606 0l5.656 5.656a7.506 7.506 0 0 1-.004 10.61 7.464 7.464 0 0 1-5.3 2.195zM450.25 409.25a7.46 7.46 0 0 1-5.305-2.2l-5.656-5.655a7.497 7.497 0 0 1 .004-10.606 7.497 7.497 0 0 1 10.605 0l5.657 5.66a7.5 7.5 0 0 1-5.305 12.801zm0 0"
            opacity="1"
            data-original="#60d882"
          ></path>
          <path
            fill="#02af8e"
            d="M105.5 184.625a7.5 7.5 0 0 1-5.855-2.813l-16.5-20.625a7.5 7.5 0 0 1 1.171-10.542 7.495 7.495 0 0 1 10.54 1.171l10.644 13.305 27.145-33.93a7.5 7.5 0 0 1 10.542-1.171 7.503 7.503 0 0 1 1.172 10.542l-33 41.25a7.514 7.514 0 0 1-5.859 2.813zM105.5 317a7.492 7.492 0 0 1-5.855-2.816l-16.5-20.625a7.495 7.495 0 0 1 1.171-10.54 7.494 7.494 0 0 1 10.54 1.169l10.644 13.304 27.145-33.93a7.499 7.499 0 0 1 10.542-1.167 7.498 7.498 0 0 1 1.172 10.539l-33 41.25A7.506 7.506 0 0 1 105.5 317zM203.5 251.25a7.492 7.492 0 0 1-5.855-2.816l-16.5-20.625a7.491 7.491 0 0 1 1.171-10.54 7.494 7.494 0 0 1 10.54 1.168l10.644 13.305 27.145-33.93a7.5 7.5 0 0 1 10.542-1.167 7.498 7.498 0 0 1 1.172 10.539l-33 41.25a7.506 7.506 0 0 1-5.859 2.816zm0 0"
            opacity="1"
            data-original="#02af8e"
          ></path>
        </g>
      </svg>
    </div>
  );
}
