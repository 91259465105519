import React from 'react';
import '../../assets/css/App.css';
export default function PlanningIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 16.933 16.933"
      >
        <g>
          <path
            fill="#ff9751"
            d="M1.058 2.646C.472 2.646 0 3.118 0 3.704v12.171c0 .586.472 1.058 1.058 1.058h9.525c.587 0 1.059-.472 1.059-1.058V3.705c0-.587-.472-1.06-1.059-1.06z"
            opacity="1"
            data-original="#ff9751"
          ></path>
          <path
            fill="#ff7f29"
            d="M2.694 2.646a.79.79 0 0 0-.048.264v1.853c0 .44.354.793.794.793H8.73c.44 0 .794-.354.794-.793V2.91a.79.79 0 0 0-.048-.264z"
            opacity="1"
            data-original="#ff7f29"
          ></path>
          <path
            fill="#ff7f29"
            d="M10.752 2.662C11.912 13.565 3.901 16.146.461 16.75c.17.116.375.184.597.184h9.525c.587 0 1.059-.472 1.059-1.058V3.705c0-.53-.385-.962-.89-1.043z"
            opacity="1"
            data-original="#ff7f29"
          ></path>
          <path
            fill="#e4eff8"
            d="M1.622 3.704a.563.563 0 0 0-.564.564v10.549c0 .312.252.563.564.563h8.398a.563.563 0 0 0 .563-.563V4.268a.563.563 0 0 0-.563-.564z"
            opacity="1"
            data-original="#e4eff8"
          ></path>
          <path
            fill="#c4dcf0"
            d="M7.938 9.79v.529h-3.44a.792.792 0 0 0-.794.793v1.059h-.53v-1.06c0-.728.589-1.322 1.323-1.322zM4.233 7.408v.53h3.44c.445 0 .794.36.794.792v1.06h.529V8.73c0-.728-.588-1.322-1.322-1.322z"
            opacity="1"
            data-original="#c4dcf0"
          ></path>
          <path
            fill="#549ad4"
            d="m10.164 6.176 3.929-3.929a.528.528 0 0 1 .748 0l.374.375a.528.528 0 0 1 0 .748l-3.929 3.929-1.496.374z"
            opacity="1"
            data-original="#549ad4"
          ></path>
          <path
            fill="#c4dcf0"
            d="M10.583 7.672c-.833 4.15-3.403 6.444-5.92 7.708h5.357a.563.563 0 0 0 .563-.563z"
            opacity="1"
            data-original="#c4dcf0"
          ></path>
          <path
            fill="#ff5555"
            d="M14.552 13.758a3.175 3.175 0 1 1-6.35 0 3.175 3.175 0 0 1 6.35 0z"
            opacity="1"
            data-original="#ff5555"
          ></path>
          <path
            fill="#ff2929"
            d="M13.347 11.273a3.175 3.175 0 0 1-2.5 5.131 3.175 3.175 0 0 1-1.97-.69 3.175 3.175 0 0 0 5.675-1.956 3.175 3.175 0 0 0-1.205-2.485z"
            opacity="1"
            data-original="#ff2929"
          ></path>
          <path
            fill="#ff9751"
            d="M3.969 7.143v.53h3.44c.445 0 .793.36.793.793v1.059h.53v-1.06c0-.728-.589-1.322-1.323-1.322z"
            opacity="1"
            data-original="#ff9751"
          ></path>
          <path
            fill="#ff7f29"
            d="M8.467 8.731v.794h.265v-1.06c0-.728-.588-1.322-1.323-1.322h-.794c1.852 0 1.852 1.06 1.852 1.588z"
            opacity="1"
            data-original="#ff7f29"
          ></path>
          <path
            fill="#ff9751"
            d="M7.673 10.054v.53h-3.44a.792.792 0 0 0-.793.792v1.06h-.53v-1.06c0-.728.588-1.322 1.323-1.322z"
            opacity="1"
            data-original="#ff9751"
          ></path>
          <path
            fill="#ff7f29"
            d="M7.144 10.054v.53h.529v-.53z"
            opacity="1"
            data-original="#ff7f29"
          ></path>
          <path
            fill="#549ad4"
            d="M7.937 9.25h1.059c.293 0 .529.236.529.53v1.058c0 .293-.236.529-.53.529H7.938a.528.528 0 0 1-.529-.53V9.78c0-.293.236-.529.53-.529z"
            opacity="1"
            data-original="#549ad4"
          ></path>
          <path
            fill="#ff7f29"
            d="M3.968 10.583a.792.792 0 0 0-.793.793v1.06h.265v-1.06c0-.433.348-.793.792-.793z"
            opacity="1"
            data-original="#ff7f29"
          ></path>
          <path
            fill="#ff5151"
            d="M2.646 12.17h1.058c.293 0 .53.237.53.53v1.058c0 .293-.237.53-.53.53H2.646a.528.528 0 0 1-.53-.53V12.7c0-.293.237-.53.53-.53z"
            opacity="1"
            data-original="#ff5151"
          ></path>
          <path
            fill="#c4dcf0"
            d="M2.646 3.704v.53c0 .44.354.793.794.793H8.73c.44 0 .794-.354.794-.794v-.529z"
            opacity="1"
            data-original="#c4dcf0"
          ></path>
          <path
            fill="#3588cb"
            d="M9.097 9.26c.101 1.12-.63 1.679-1.539 1.947.096.098.23.16.38.16h1.058c.293 0 .529-.236.529-.53V9.78a.527.527 0 0 0-.428-.519z"
            opacity="1"
            data-original="#3588cb"
          ></path>
          <path
            fill="#38668c"
            d="M5.82 0c-.73 0-1.322.592-1.322 1.323H3.175a.792.792 0 0 0-.794.794v1.852c0 .44.354.794.794.794h5.292c.44 0 .793-.355.793-.794V2.117a.792.792 0 0 0-.793-.794H7.144C7.144.593 6.55 0 5.82 0z"
            opacity="1"
            data-original="#38668c"
          ></path>
          <path
            fill="#2c5170"
            d="M2.948 4.726a.788.788 0 0 0 .227.037h5.292c.44 0 .793-.355.793-.794V2.117a.79.79 0 0 0-.693-.784c-.27 2.526-3.413 3.219-5.619 3.393z"
            opacity="1"
            data-original="#2c5170"
          ></path>
          <path
            fill="#e4eff8"
            d="M6.085 1.323a.265.265 0 1 1-.529 0 .265.265 0 0 1 .53 0z"
            opacity="1"
            data-original="#e4eff8"
          ></path>
          <path
            fill="#ff2929"
            d="M3.805 12.181c.102 1.119-.63 1.678-1.538 1.946.096.099.23.16.379.16h1.058c.293 0 .53-.236.53-.529V12.7a.527.527 0 0 0-.429-.519z"
            opacity="1"
            data-original="#ff2929"
          ></path>
          <path
            fill="#ff5151"
            d="M2.646 6.35h1.058c.293 0 .53.236.53.53v1.057c0 .294-.237.53-.53.53H2.646a.528.528 0 0 1-.53-.53V6.88c0-.293.237-.529.53-.529z"
            opacity="1"
            data-original="#ff5151"
          ></path>
          <path
            fill="#ff7f29"
            d="M3.969 7.144v.53h.529v-.53z"
            opacity="1"
            data-original="#ff7f29"
          ></path>
          <path
            fill="#ff2929"
            d="M3.805 6.36c.102 1.12-.63 1.679-1.538 1.946.096.1.23.16.379.16h1.058c.293 0 .53-.235.53-.528V6.879a.527.527 0 0 0-.429-.519z"
            opacity="1"
            data-original="#ff2929"
          ></path>
          <path
            fill="#e4eff8"
            d="M13.494 13.758a2.117 2.117 0 1 1-4.234 0 2.117 2.117 0 0 1 4.234 0z"
            opacity="1"
            data-original="#e4eff8"
          ></path>
          <path
            fill="#ff5555"
            d="M12.435 13.758a1.058 1.058 0 1 1-2.116 0 1.058 1.058 0 0 1 2.116 0z"
            opacity="1"
            data-original="#ff5555"
          ></path>
          <path
            fill="#ff2929"
            d="M11.811 12.794a1.058 1.058 0 0 1-1.397 1.4 1.058 1.058 0 1 0 1.397-1.4z"
            opacity="1"
            data-original="#ff2929"
          ></path>
          <path
            fill="#c4dcf0"
            d="M12.58 12.017c.25.355.384.778.385 1.212a2.117 2.117 0 0 1-3.32 1.741 2.117 2.117 0 1 0 2.936-2.953z"
            opacity="1"
            data-original="#c4dcf0"
          ></path>
          <path
            fill="#3588cb"
            d="m14.686 2.84-3.929 3.93-.791.198-.176.705 1.496-.374 3.93-3.93a.528.528 0 0 0 0-.747l-.375-.375a.532.532 0 0 0-.086-.069.527.527 0 0 1-.07.663z"
            opacity="1"
            data-original="#3588cb"
          ></path>
          <path
            fill="#e4eff8"
            d="m13.618 2.722.475-.475a.528.528 0 0 1 .748 0l.374.375a.528.528 0 0 1 0 .748l-.38.38z"
            opacity="1"
            data-original="#e4eff8"
          ></path>
          <g fill="#549ad4">
            <path
              d="M5.292 12.965c-.353 0-.353.529 0 .529h2.38c.354 0 .354-.53 0-.53zM5.292 14.023c-.353 0-.353.53 0 .53h2.38c.354 0 .354-.53 0-.53zM5.292 11.906c-.353 0-.353.53 0 .53h1.322c.353 0 .353-.53 0-.53z"
              fill="#549ad4"
              opacity="1"
              data-original="#549ad4"
            ></path>
          </g>
          <path
            fill="#3588cb"
            d="M6.79 14.023c-.235.187-.472.365-.713.53h1.596c.353 0 .353-.53 0-.53zM7.864 13.042c-.149.157-.301.307-.456.452h.265c.268 0 .331-.305.191-.452z"
            opacity="1"
            data-original="#3588cb"
          ></path>
          <path
            fill="#549ad4"
            d="m13.192 10.073.187 1.684 1.683.187 1.871-1.871-1.31-.561-.56-1.31z"
            opacity="1"
            data-original="#549ad4"
          ></path>
          <path
            fill="#549ad4"
            d="M14.469 10.395a.265.265 0 0 0-.182.08L11.19 13.57c-.25.25.125.625.375.375l3.098-3.097a.265.265 0 0 0-.193-.453z"
            opacity="1"
            data-original="#549ad4"
          ></path>
          <path
            fill="#3588cb"
            d="m13.379 11.757 1.683.187 1.871-1.871-1.31-.561z"
            opacity="1"
            data-original="#3588cb"
          ></path>
          <path
            fill="#c4dcf0"
            d="M14.778 2.182a.528.528 0 0 1-.069.663l-.381.382-.644-.545-.045.044 1.219 1.03.381-.38a.529.529 0 0 0 0-.75l-.375-.375a.536.536 0 0 0-.086-.07z"
            opacity="1"
            data-original="#c4dcf0"
          ></path>
        </g>
      </svg>
    </div>
  );
}
