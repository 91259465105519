import React from 'react';
import '../../assets/css/App.css';
export default function SurveyIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 512 512"
        className="hovered-paths"
      >
        <g>
          <path
            fill="#00c8c8"
            d="M83.484 434.142A248.075 248.075 0 0 0 379.851 470.9L252.984 280.342z"
            opacity="1"
            data-original="#00c8c8"
          ></path>
          <path
            fill="#f9b534"
            d="M252.861 240.312c.01 0 .019-.005.029-.007L495.7 192.2C467.543 86.151 370.905 8 256 8 119.033 8 8 119.033 8 256a246.939 246.939 0 0 0 53.973 154.451l183.276-166.3a16 16 0 0 1 7.612-3.839z"
            opacity="1"
            data-original="#f9b534"
          ></path>
          <path
            fill="#eb3958"
            d="M406.465 453.134A247.584 247.584 0 0 0 504 256a250.223 250.223 0 0 0-2.108-32.409l-219.316 43.455z"
            opacity="1"
            data-original="#eb3958"
          ></path>
          <path
            fill="#50cdc8"
            d="M283.56 474.048a247.4 247.4 0 0 0 87.74-15.988L252.984 280.342 113.7 406.725a247.126 247.126 0 0 0 169.86 67.323z"
            opacity="1"
            data-original="#50cdc8"
          ></path>
          <path
            fill="#f9cb4e"
            d="M256 8A247.1 247.1 0 0 0 87.838 73.741a248 248 0 0 0 4 309.614l153.415-139.2a16 16 0 0 1 7.612-3.839c.01 0 .019-.005.029-.007L495.7 192.2C467.543 86.151 370.905 8 256 8z"
            opacity="1"
            data-original="#f9cb4e"
            className="hovered-path"
          ></path>
          <path
            fill="#f0516e"
            d="M400.8 444.627a248.644 248.644 0 0 0 50.854-36.257A246.867 246.867 0 0 0 504 256a250.223 250.223 0 0 0-2.108-32.409l-219.316 43.455z"
            opacity="1"
            data-original="#f0516e"
          ></path>
          <g fill="#fff">
            <path
              d="M194.714 165.029a39.4 39.4 0 0 0-42.994 0 11.592 11.592 0 0 0-5.006 9.544s-.008 14.406 0 14.406l53.006.021v-14.427a11.593 11.593 0 0 0-5.006-9.544z"
              fill="#ffffff"
              opacity="1"
              data-original="#ffffff"
            ></path>
            <path
              d="M175.891 125h-5.347a10.757 10.757 0 0 0-10.757 10.757v6.659a17.1 17.1 0 0 0 6.263 13.231l.958.785a9.8 9.8 0 0 0 12.419 0l.957-.785a17.106 17.106 0 0 0 6.264-13.231v-6.659A10.757 10.757 0 0 0 175.891 125zM217.291 427.831a39.4 39.4 0 0 0-42.994 0 11.6 11.6 0 0 0-5 9.544s-.008 14.407 0 14.407l53.006.014v-14.42a11.592 11.592 0 0 0-5.012-9.545z"
              fill="#ffffff"
              opacity="1"
              data-original="#ffffff"
            ></path>
            <path
              d="M198.468 387.8h-5.347a10.757 10.757 0 0 0-10.757 10.757v6.66a17.106 17.106 0 0 0 6.264 13.231l.957.785a9.8 9.8 0 0 0 12.419 0l.958-.785a17.106 17.106 0 0 0 6.264-13.231v-6.66a10.758 10.758 0 0 0-10.758-10.757zM401.383 312.731a39.4 39.4 0 0 0-42.994 0 11.6 11.6 0 0 0-5 9.544s-.008 14.407 0 14.407l53.006.014v-14.42a11.594 11.594 0 0 0-5.012-9.545z"
              fill="#ffffff"
              opacity="1"
              data-original="#ffffff"
            ></path>
            <path
              d="M382.56 272.7h-5.347a10.757 10.757 0 0 0-10.757 10.757v6.66a17.106 17.106 0 0 0 6.264 13.231l.957.785a9.8 9.8 0 0 0 12.419 0l.958-.785a17.1 17.1 0 0 0 6.263-13.231v-6.66A10.757 10.757 0 0 0 382.56 272.7zM279.576 127.339a8 8 0 0 0-11.313 0l-48 48a8 8 0 1 0 11.313 11.314l48-48a8 8 0 0 0 0-11.314zM268.983 181a8 8 0 0 0-8-8h-6a8 8 0 0 0 0 16h6a8 8 0 0 0 8-8zM238.49 141h6a8 8 0 0 0 0-16h-6a8 8 0 1 0 0 16zM290.84 390.141l-48 48a8 8 0 0 0 11.314 11.314l48-48a8 8 0 0 0-11.314-11.314zM283.56 435.8h-6a8 8 0 1 0 0 16h6a8 8 0 0 0 0-16zM261.067 403.8h6a8 8 0 0 0 0-16h-6a8 8 0 0 0 0 16zM486.246 275.041a8 8 0 0 0-11.314 0l-48 48a8 8 0 0 0 11.314 11.314l48-48a8 8 0 0 0 0-11.314zM467.652 320.7h-6a8 8 0 1 0 0 16h6a8 8 0 0 0 0-16zM445.159 288.7h6a8 8 0 0 0 0-16h-6a8 8 0 0 0 0 16z"
              fill="#ffffff"
              opacity="1"
              data-original="#ffffff"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
}
