import React from 'react';
import '../../assets/css/App.css';
export default function DiscussIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 50 50"
      >
        <g>
          <path
            fill="#ffb95c"
            d="M37 10.4c0 1.5.6 2.8 1.5 3.8 1 1.2 2.6 1.9 4.3 1.9s3.2-.7 4.3-1.9c.9-1 1.5-2.3 1.5-3.8 0-3.2-2.6-5.7-5.7-5.7-3.3 0-5.9 2.6-5.9 5.7z"
            opacity="1"
            data-original="#ffb95c"
          ></path>
          <path
            fill="#682f2a"
            d="M42.8 16.6c-1.8 0-3.6-.8-4.7-2.1-1.1-1.2-1.6-2.6-1.6-4.1 0-3.4 2.9-6.2 6.4-6.2 3.4 0 6.2 2.8 6.2 6.2 0 1.5-.6 3-1.6 4.1-1.3 1.4-2.9 2.1-4.7 2.1zm.1-11.4c-3 0-5.4 2.3-5.4 5.2 0 1.6.7 2.8 1.4 3.5.9 1.1 2.4 1.7 3.9 1.7s2.9-.6 3.9-1.7c.6-.7 1.4-1.9 1.4-3.5 0-2.9-2.3-5.2-5.2-5.2z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <circle
            cx="42.8"
            cy="8.9"
            r="1.8"
            fill="#ffffff"
            opacity="1"
            data-original="#ffffff"
          ></circle>
          <path
            fill="#682f2a"
            d="M42.8 11.2c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3zm0-3.6c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3-.6-1.3-1.3-1.3z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#ffffff"
            d="M38.5 14.3c1 1.2 2.6 1.9 4.3 1.9s3.2-.7 4.3-1.9c-.4-2-2.1-3.6-4.3-3.6s-4 1.5-4.3 3.6z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#682f2a"
            d="M42.8 16.7c-1.8 0-3.6-.8-4.7-2.1-.1-.1-.1-.2-.1-.4.3-2.3 2.3-4 4.8-4 2.3 0 4.3 1.6 4.8 4 0 .2 0 .3-.1.4-1.2 1.4-2.9 2.1-4.7 2.1zM39 14.1c.9 1 2.3 1.6 3.8 1.6 1.4 0 2.7-.5 3.8-1.6-.5-1.7-2-2.9-3.8-2.9-1.9 0-3.4 1.2-3.8 2.9z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#fa4968"
            d="M13 10.4c0 1.5-.6 2.8-1.5 3.8-1 1.2-2.6 1.9-4.3 1.9s-3.2-.7-4.3-1.9c-.9-1-1.5-2.3-1.5-3.8 0-3.2 2.6-5.7 5.7-5.7 3.3 0 5.9 2.6 5.9 5.7z"
            opacity="1"
            data-original="#fa4968"
          ></path>
          <path
            fill="#682f2a"
            d="M7.2 16.6c-1.8 0-3.4-.7-4.7-2.1C1.4 13.3.9 11.9.9 10.4.9 7 3.7 4.2 7.1 4.2c3.5 0 6.4 2.8 6.4 6.2 0 1.5-.6 3-1.6 4.1-1.1 1.3-2.9 2.1-4.7 2.1zM7.1 5.2c-2.9 0-5.2 2.3-5.2 5.2 0 1.6.7 2.8 1.4 3.5 1 1.1 2.4 1.7 3.9 1.7 1.6 0 3-.6 3.9-1.7.6-.7 1.4-1.9 1.4-3.5 0-2.9-2.4-5.2-5.4-5.2z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <circle
            cx="7.2"
            cy="8.9"
            r="1.8"
            fill="#ffffff"
            opacity="1"
            data-original="#ffffff"
          ></circle>
          <path
            fill="#682f2a"
            d="M7.2 11.2c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3zm0-3.6c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3-.6-1.3-1.3-1.3z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#ffffff"
            d="M11.5 14.3c-1 1.2-2.6 1.9-4.3 1.9s-3.2-.7-4.3-1.9c.4-2 2.1-3.6 4.3-3.6s4 1.5 4.3 3.6z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#682f2a"
            d="M7.2 16.7c-1.8 0-3.4-.7-4.7-2.1-.1-.1-.2-.3-.1-.4.5-2.4 2.4-4 4.8-4s4.5 1.7 4.8 4c0 .1 0 .3-.1.4-1.1 1.3-2.9 2.1-4.7 2.1zm-3.8-2.6c1 1 2.3 1.6 3.8 1.6s2.9-.6 3.8-1.6c-.4-1.7-1.9-2.9-3.8-2.9-1.8 0-3.3 1.2-3.8 2.9z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#6cd8d5"
            d="M37 39.6c0 1.5.6 2.8 1.5 3.8 1 1.2 2.6 1.9 4.3 1.9s3.2-.7 4.3-1.9c.9-1 1.5-2.3 1.5-3.8 0-3.2-2.6-5.7-5.7-5.7-3.3-.1-5.9 2.5-5.9 5.7z"
            opacity="1"
            data-original="#6cd8d5"
          ></path>
          <path
            fill="#682f2a"
            d="M42.8 45.8c-1.8 0-3.6-.8-4.7-2.1-1.1-1.2-1.6-2.6-1.6-4.1 0-3.4 2.8-6.2 6.2-6.2h.2c3.4 0 6.2 2.8 6.2 6.2 0 1.5-.6 3-1.6 4.1-1.3 1.4-2.9 2.1-4.7 2.1zm-.1-11.4c-2.9 0-5.2 2.3-5.2 5.2 0 1.6.7 2.8 1.4 3.5.9 1.1 2.4 1.7 3.9 1.7s2.9-.6 3.9-1.7c.6-.7 1.4-1.9 1.4-3.5 0-2.9-2.3-5.2-5.2-5.2z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <circle
            cx="42.8"
            cy="38"
            r="1.8"
            fill="#ffffff"
            opacity="1"
            data-original="#ffffff"
          ></circle>
          <path
            fill="#682f2a"
            d="M42.8 40.3c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3zm0-3.6c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3-.6-1.3-1.3-1.3z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#ffffff"
            d="M38.5 43.4c1 1.2 2.6 1.9 4.3 1.9s3.2-.7 4.3-1.9c-.4-2-2.1-3.6-4.3-3.6s-4 1.6-4.3 3.6z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#682f2a"
            d="M42.8 45.8c-1.8 0-3.6-.8-4.7-2.1-.1-.1-.1-.2-.1-.4.3-2.3 2.4-4 4.8-4 2.3 0 4.3 1.6 4.8 4 0 .2 0 .3-.1.4-1.3 1.4-2.9 2.1-4.7 2.1zM39 43.3c.9 1 2.3 1.5 3.8 1.5 1.4 0 2.7-.5 3.8-1.6-.5-1.7-2-2.9-3.8-2.9s-3.4 1.3-3.8 3z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#559aff"
            d="M13 39.6c0 1.5-.6 2.8-1.5 3.8-1 1.2-2.6 1.9-4.3 1.9s-3.2-.7-4.3-1.9c-.9-1-1.5-2.3-1.5-3.8 0-3.2 2.6-5.7 5.7-5.7 3.3-.1 5.9 2.5 5.9 5.7z"
            opacity="1"
            data-original="#559aff"
          ></path>
          <path
            fill="#682f2a"
            d="M7.2 45.8c-1.8 0-3.4-.7-4.7-2.1C1.4 42.5.9 41.1.9 39.6c0-3.4 2.8-6.2 6.2-6.2h.2c3.4 0 6.2 2.8 6.2 6.2 0 1.5-.6 3-1.6 4.1-1.1 1.3-2.9 2.1-4.7 2.1zm.1-11.4h-.2c-2.9 0-5.2 2.3-5.2 5.2 0 1.6.7 2.8 1.4 3.5 1 1.1 2.4 1.7 3.9 1.7 1.6 0 3-.6 3.9-1.7.6-.7 1.4-1.9 1.4-3.5 0-2.9-2.3-5.2-5.2-5.2z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <circle
            cx="7.2"
            cy="38"
            r="1.8"
            fill="#ffffff"
            opacity="1"
            data-original="#ffffff"
          ></circle>
          <path
            fill="#682f2a"
            d="M7.2 40.3c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3zm0-3.6c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3-.6-1.3-1.3-1.3z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#ffffff"
            d="M11.5 43.4c-1 1.2-2.6 1.9-4.3 1.9s-3.2-.7-4.3-1.9c.4-2 2.1-3.6 4.3-3.6s4 1.6 4.3 3.6z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#682f2a"
            d="M7.2 45.8c-1.8 0-3.4-.7-4.7-2.1-.1-.1-.2-.3-.1-.4.5-2.4 2.4-4 4.8-4s4.5 1.7 4.8 4c0 .1 0 .3-.1.4-1.1 1.3-2.9 2.1-4.7 2.1zm-3.8-2.6c1 1 2.3 1.6 3.8 1.6s2.9-.6 3.8-1.5c-.4-1.7-1.9-3-3.8-3-1.8 0-3.3 1.2-3.8 2.9z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#559aff"
            d="M25.9 25.2v4.5c0 1-.8 1.9-1.9 1.9h-7.2L13.4 34v-8.8c0-1 .8-1.9 1.9-1.9H24c1.1 0 1.9.8 1.9 1.9z"
            opacity="1"
            data-original="#559aff"
          ></path>
          <path
            fill="#682f2a"
            d="M13.4 34.5c-.1 0-.2 0-.2-.1-.2-.1-.3-.3-.3-.4v-8.8c0-1.3 1.1-2.4 2.4-2.4H24c1.4 0 2.4 1 2.4 2.4v4.5c0 1.3-1.1 2.4-2.4 2.4h-7l-3.3 2.3c-.1.1-.2.1-.3.1zm1.9-10.7c-.9 0-1.4.7-1.4 1.4V33l2.6-1.8c.1-.1.2-.1.3-.1H24c.9 0 1.4-.7 1.4-1.4v-4.5c0-.8-.6-1.4-1.4-1.4z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#ffffff"
            d="M22.6 26.8h-5.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5.9c.3 0 .5.2.5.5s-.2.5-.5.5zM20 29h-3.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H20c.3 0 .5.2.5.5s-.2.5-.5.5z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#6cd8d5"
            d="M21.9 31.1v4.5c0 1 .8 1.9 1.9 1.9H31l3.5 2.5v-8.8c0-1-.8-1.9-1.9-1.9h-8.8c-1.1-.1-1.9.7-1.9 1.8z"
            opacity="1"
            data-original="#6cd8d5"
          ></path>
          <path
            fill="#682f2a"
            d="M34.5 40.5c-.1 0-.2 0-.3-.1L30.8 38h-7c-1.3 0-2.4-1.1-2.4-2.4v-4.5c0-1.4 1.1-2.4 2.4-2.3h8.8c1.3 0 2.4 1.1 2.4 2.4V40c0 .2-.1.4-.3.4 0 .1-.1.1-.2.1zM23.6 29.8c-.7 0-1.2.5-1.2 1.3v4.5c0 .7.5 1.4 1.4 1.4H31c.1 0 .2 0 .3.1L34 39v-7.8c0-.7-.5-1.4-1.4-1.4h-9z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#ffffff"
            d="M31.1 32.7h-5.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5.9c.3 0 .5.2.5.5s-.2.5-.5.5zM31.1 34.9h-3.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.3c.3 0 .5.2.5.5s-.2.5-.5.5z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#ffb95c"
            d="M24.4 25.1v-4.5c0-1 .8-1.9 1.9-1.9h7.2l3.5-2.5V25c0 1-.8 1.9-1.9 1.9h-8.8c-1.1.1-1.9-.7-1.9-1.8z"
            opacity="1"
            data-original="#ffb95c"
          ></path>
          <path
            fill="#682f2a"
            d="M26.1 27.4c-1.3 0-2.2-1-2.2-2.3v-4.5c0-1.3 1.1-2.4 2.4-2.4h7l3.4-2.4c.2-.1.4-.1.5 0 .2.1.3.3.3.4V25c0 1.3-1.1 2.4-2.4 2.4h-9zm.2-8.2c-.9 0-1.4.7-1.4 1.4v4.5c0 .8.6 1.4 1.4 1.3h8.8c.9 0 1.4-.7 1.4-1.4v-7.8l-2.7 1.9c-.1.1-.2.1-.3.1z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#ffffff"
            d="M33.6 24.5h-5.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5.9c.3 0 .5.2.5.5s-.2.5-.5.5zM33.6 22.3h-3.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.3c.3 0 .5.2.5.5s-.2.5-.5.5z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#fa4968"
            d="M28.4 19.2v-4.5c0-1-.8-1.9-1.9-1.9h-7.2l-3.5-2.5v8.8c0 1 .8 1.9 1.9 1.9h8.8c1.1.1 1.9-.7 1.9-1.8z"
            opacity="1"
            data-original="#fa4968"
          ></path>
          <path
            fill="#682f2a"
            d="M26.7 21.5h-9c-1.3 0-2.4-1.1-2.4-2.4v-8.8c0-.2.1-.4.3-.4.2-.1.4-.1.5 0l3.4 2.4h7c1.3 0 2.4 1.1 2.4 2.4v4.5c0 .6-.2 1.2-.6 1.6-.4.5-1 .7-1.6.7zM16.3 11.3v7.8c0 .7.5 1.4 1.4 1.4h9c.3 0 .7-.1.9-.4.2-.2.3-.6.3-1v-4.5c0-.7-.5-1.4-1.4-1.4h-7.2c-.1 0-.2 0-.3-.1z"
            opacity="1"
            data-original="#682f2a"
          ></path>
          <path
            fill="#ffffff"
            d="M25.1 18.6h-5.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5.9c.3 0 .5.2.5.5s-.2.5-.5.5zM22.5 16.4h-3.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.3c.3 0 .5.2.5.5s-.2.5-.5.5z"
            opacity="1"
            data-original="#ffffff"
          ></path>
        </g>
      </svg>
    </div>
  );
}
