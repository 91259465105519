import React from 'react';
import '../../assets/css/App.css';
export default function AppraisalIcon() {
  return (
    <div className="iconContainer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80%"
        height="80%"
        x="0"
        y="0"
        viewBox="0 0 256 256"
      >
        <g>
          <path
            fill="#f1f2f2"
            d="M144.173 8.001H16.413c-5.523 0-10 4.477-10 10V238c0 5.523 4.477 10 10 10h168.43c5.523 0 10-4.477 10-10V58.653z"
            opacity="1"
            data-original="#f1f2f2"
          ></path>
          <path
            fill="#ffffff"
            d="M12.413 88.75a2 2 0 0 1-2-2v-57a2 2 0 0 1 4 0v57a2 2 0 0 1-2 2zm0-65a2 2 0 0 1-2-2v-.5a2 2 0 0 1 4 0v.5a2 2 0 0 1-2 2z"
            opacity="1"
            data-original="#ffffff"
          ></path>
          <path
            fill="#e6e7e8"
            d="m194.843 58.653-10 .002V228c0 5.523-4.477 10-10 10H6.413c0 5.523 4.477 10 10 10h168.43c5.523 0 10-4.477 10-10z"
            opacity="1"
            data-original="#e6e7e8"
          ></path>
          <path
            fill="#d1d3d4"
            d="m194.843 58.653-5 .001V233c0 5.523-4.477 10-10 10H11.413a9.954 9.954 0 0 1-4.11-.89c1.569 3.471 5.053 5.89 9.11 5.89h168.43c5.523 0 10-4.477 10-10z"
            opacity="1"
            data-original="#d1d3d4"
          ></path>
          <path
            fill="#939598"
            d="M144.173 48.66V8l50.669 50.653-40.667.007c-5.523.001-10.002-4.476-10.002-10z"
            opacity="1"
            data-original="#939598"
          ></path>
          <path
            fill="#808285"
            d="m189.841 53.653-40.666.007a9.94 9.94 0 0 1-4.111-.891c1.569 3.471 5.054 5.891 9.111 5.891l40.667-.007z"
            opacity="1"
            data-original="#808285"
          ></path>
          <path
            fill="#76a34f"
            d="M45.305 106.556a4.976 4.976 0 0 1-3.418-1.352l-8.945-8.385a5 5 0 0 1 6.838-7.296l5.3 4.968 12.635-13.467a5 5 0 0 1 7.293 6.841l-16.055 17.111a4.99 4.99 0 0 1-3.648 1.58z"
            opacity="1"
            data-original="#76a34f"
          ></path>
          <path
            fill="#414042"
            d="M132.295 106.978H79.628a2 2 0 0 1 0-4h52.667a2 2 0 0 1 0 4z"
            opacity="1"
            data-original="#414042"
          ></path>
          <path
            fill="#d10028"
            d="m51.978 133 5.79-6.171a5 5 0 0 0-7.293-6.841l-5.354 5.706-5.354-5.706a5 5 0 0 0-7.293 6.841l5.79 6.171-5.79 6.171a4.999 4.999 0 0 0 3.646 8.421c1.333 0 2.663-.53 3.647-1.579l5.354-5.706 5.354 5.706a4.985 4.985 0 0 0 3.647 1.579 5 5 0 0 0 3.646-8.421z"
            opacity="1"
            data-original="#d10028"
          ></path>
          <path
            fill="#414042"
            d="M132.295 146.978H79.628a2 2 0 0 1 0-4h52.667a2 2 0 0 1 0 4z"
            opacity="1"
            data-original="#414042"
          ></path>
          <path
            fill="#76a34f"
            d="M45.305 186.556a4.976 4.976 0 0 1-3.418-1.352l-8.945-8.385a4.999 4.999 0 0 1-.229-7.067 4.999 4.999 0 0 1 7.067-.229l5.3 4.968 12.635-13.467a5 5 0 0 1 7.293 6.841l-16.055 17.111a4.99 4.99 0 0 1-3.648 1.58z"
            opacity="1"
            data-original="#76a34f"
          ></path>
          <path
            fill="#414042"
            d="M107.295 186.978H79.628a2 2 0 0 1 0-4h27.667a2 2 0 0 1 0 4z"
            opacity="1"
            data-original="#414042"
          ></path>
          <path
            fill="#76a34f"
            d="M45.305 226.556a4.976 4.976 0 0 1-3.418-1.352l-8.945-8.385a5 5 0 0 1 6.838-7.296l5.3 4.968 12.635-13.467a5 5 0 0 1 7.293 6.841l-16.055 17.111a4.99 4.99 0 0 1-3.648 1.58z"
            opacity="1"
            data-original="#76a34f"
          ></path>
          <path
            fill="#414042"
            d="M107.295 226.978H79.628a2 2 0 0 1 0-4h27.667a2 2 0 0 1 0 4z"
            opacity="1"
            data-original="#414042"
          ></path>
          <path
            fill="#ffc91d"
            d="m48.127 32.869 2.841 8.745h9.195c1.578 0 2.234 2.019.958 2.947l-7.439 5.404 2.841 8.745c.488 1.501-1.23 2.749-2.507 1.821l-7.439-5.404-7.439 5.404c-1.277.928-2.994-.32-2.507-1.821l2.841-8.745-7.439-5.404c-1.277-.928-.621-2.947.958-2.947h9.195l2.841-8.745c.489-1.501 2.613-1.501 3.1 0zM84.91 32.869l2.841 8.745h9.195c1.578 0 2.234 2.019.958 2.947l-7.439 5.404 2.841 8.745c.488 1.501-1.23 2.749-2.507 1.821l-7.439-5.404-7.439 5.404c-1.277.928-2.994-.32-2.507-1.821l2.841-8.745-7.439-5.404c-1.277-.928-.621-2.947.958-2.947h9.195l2.841-8.745c.489-1.501 2.612-1.501 3.1 0zM121.693 32.869l2.841 8.745h9.195c1.578 0 2.234 2.019.958 2.947l-7.439 5.404 2.841 8.745c.488 1.501-1.23 2.749-2.507 1.821l-7.439-5.404-7.439 5.404c-1.277.928-2.994-.32-2.507-1.821l2.841-8.745-7.439-5.404c-1.277-.928-.621-2.947.958-2.947h9.195l2.841-8.745c.489-1.501 2.612-1.501 3.1 0z"
            opacity="1"
            data-original="#ffc91d"
          ></path>
          <path
            fill="#478cf7"
            d="M239.587 248h-110c-5.523 0-10-4.477-10-10v-43.082c0-16.569 13.431-30 30-30h70c16.569 0 30 13.431 30 30V238c0 5.523-4.477 10-10 10z"
            opacity="1"
            data-original="#478cf7"
          ></path>
          <path
            fill="#377de2"
            d="M238.147 171.358a29.86 29.86 0 0 1 6.44 18.56V233c0 5.523-4.477 10-10 10h-110a9.954 9.954 0 0 1-4.11-.89c1.569 3.471 5.053 5.89 9.11 5.89h110c5.523 0 10-4.477 10-10v-43.082c0-9.559-4.476-18.067-11.44-23.56z"
            opacity="1"
            data-original="#377de2"
          ></path>
          <g fill="#2266d3">
            <path
              d="m184.943 170.911 14.488 17.9c.655.809 1.938.62 2.331-.345l9.589-23.548h-5.977a38.83 38.83 0 0 1-20.431 5.993zM163.8 164.917h-5.977l9.589 23.548a1.368 1.368 0 0 0 2.331.345l14.488-17.9a38.809 38.809 0 0 1-20.431-5.993z"
              fill="#2266d3"
              opacity="1"
              data-original="#2266d3"
            ></path>
          </g>
          <path
            fill="#b7022d"
            d="m184.586 170.472-8.813 10.889 4.711 10.845h8.205l4.711-10.845z"
            opacity="1"
            data-original="#b7022d"
          ></path>
          <path
            fill="#d10028"
            d="M188.689 192.206h-8.205L176.641 248h15.892z"
            opacity="1"
            data-original="#d10028"
          ></path>
          <path
            fill="#b7022d"
            d="m176.985 243-.344 5h15.891l-.344-5z"
            opacity="1"
            data-original="#b7022d"
          ></path>
          <circle
            cx="184.587"
            cy="131.893"
            r="39.027"
            fill="#ffd2c0"
            opacity="1"
            data-original="#ffd2c0"
          ></circle>
          <path
            fill="#fcc4ae"
            d="M209.567 101.913a38.866 38.866 0 0 1 9.047 24.98c0 21.554-17.473 39.026-39.027 39.026-9.504 0-18.211-3.4-24.98-9.046 7.159 8.583 17.93 14.046 29.98 14.046 21.554 0 39.027-17.473 39.027-39.026 0-12.05-5.465-22.821-14.047-29.98z"
            opacity="1"
            data-original="#fcc4ae"
          ></path>
        </g>
      </svg>
    </div>
  );
}
